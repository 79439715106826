import { useSetRecoilState } from "recoil"
import { Col, Container, Row, Table, Badge } from "../../components/UI"
import { dynamicNavs, UserIDSelector } from "../../states/atoms"
import { gql, useQuery } from "@apollo/client"
import { useLayoutEffect } from "react"
import { useRecoilValue } from "recoil"
import CardHori from "../../components/lectureCard/cardHori"
import {PageContainer} from '../addCoursePg/addcoursepg.style'

const QueryDrafted = gql`
    query GetDrafts($facId: String!, $offset: Int, $limit: Int) {
        GetDrafts(facId: $facId, offset: $offset, limit: $limit) {
            id
            lid
            instiId
            facId
            facName
            facDep
            subject
            ltitle
            stitle
            cover {
                imgId
                provider
            }
            isDemo
            status
            lcount
            ltype
        }
    }
`

const CourseDraft = () => {
    const setDynamicNav = useSetRecoilState(dynamicNavs)
    const userid = useRecoilValue(UserIDSelector)
    useLayoutEffect(() => setDynamicNav(1))

    const {data, loading, error} = useQuery(QueryDrafted, {
        variables: {
            facId: userid,
            offset: 0,
            limit: 10
        }
    })

    data && console.log(data)
    if(data)
    return(
        <PageContainer>
            <Row col="3">
                {data.GetDrafts.map((row) => {
                    return(
                        <Col key={row.id}>
                            <CardHori ltitle={row.ltitle} stitle={row.stitle} subject={row.subject} cover={row.cover} />
                        </Col>
                    )
                })}
            </Row>
        </PageContainer>
    )
}

export default CourseDraft