import Modal from "./modal"
import { styled } from "../../../stitches.config"

const PromptFooter = styled("div", {
    width: 'calc(100% + 1rem)',
    padding: '1rem',
    borderRadius: 10,
    margin: '2rem -0.5rem -0.5rem -0.5rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .btnCont': {
        display: 'flex',
        flexDirection: 'row'
    },

    '& .btnCont > button > span': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    variants: {
        color: {
            success: {
                background: '$green1',
                color: '$green9'
            },
            warning: {
                background: '$warning',
                color: '$amber9'
            },
            info: {
                background: '$blue1',
                color: '$blue9'
            },
            danger: {
                background: '$red1',
                color: '$red9'
            }
        }
    }
})

const PromptFooterContainer = ({children, color}) => {
    return(
        <PromptFooter color={color}>
            {children}
        </PromptFooter>
    )
}

const PromptContainer = ({title, children, show, setPromptState}) => {
    
    return(
        <Modal.Container title={title} width="small" vAlign="startCenter" show={show} closeModalBtn={setPromptState}>
            <Modal.Body>
                {children}
            </Modal.Body>
            
        </Modal.Container>
    )
}

const Prompt = {
    Container: PromptContainer,
    Footer: PromptFooterContainer
}

export default Prompt