import { Container, Row, Col, Card, Badge, Info, ProgressButton, Button } from "../../../components/UI"
import { useDropzone } from "react-dropzone";
import { useState, useEffect } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { CurrentNotification, UserIDSelector } from "../../../states/atoms";
import { StyledDropZone } from "../uploadVideo/videoUpl.style";
import { Icon } from "@iconify/react";
import { store } from "../../../states/db"
import { PrevCard, ImgDetails } from "./coverImage.style";
import imageUpload from '../../../components/upload/imgUpl'
import prettyBytes from "pretty-bytes";
import CardVert from "../../../components/lectureCard/cardVert";

const CoverImage = ({savedCourse, cid}) => {
    const [files, setFiles] = useState([]);
    const [meta, setMeta] = useState({ltitle: 'Preview: Title of The Lecture', stitle: 'Part 1', subject: 'Physics'})
    const setCNotify = useSetRecoilState(CurrentNotification)
    const [coverDet, setCoverDet] = useState(savedCourse || null)
    const useridstate = useRecoilValue(UserIDSelector)
    const [uplState, setUplState] = useState(coverDet?.imgId ? 2 : 0)
    
    console.table(coverDet)
    useEffect(() => {
        const getmeta = async() => {
            const val = await store.currentEdits.get(`${useridstate}metaFormCurr`)
            setMeta(val?.value)   
        }

        getmeta()
        
    },[])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg','.jpeg'],
            'image/webp': ['.webp']
        },
        onDrop: acceptedFiles => {
            
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));

        }
    });

    const uplFn = async() => {
        let imgSrc
        if(files.length > 0){
            const formData = new FormData()
            formData.append('file', files[0])
            console.log(files)

            setUplState(1)
            const URLdata = await imageUpload()
            const res = await fetch(URLdata, {
                method: 'post',
                body: formData
            })

            const {result: {id:imgId, variants: imgvar}} = await res.json()
            imgSrc = {imgId, provider: 'cf', lfile: {name: files[0].name, size: files[0].size, type: files[0].type, lm: files[0].lastModified}}
            console.log(imgvar)
            setUplState(2)
            setCoverDet(imgSrc)

            if(savedCourse && cid){
                store.savedCourses.where('id').equals(cid).modify(x => Object.assign(imgSrc))
                .then(() => {
                    setCNotify({title: 'Cover Pic Uploaded', Exp: 'to Server/Local', icon: 'iconoir:db'})
                }).catch(() =>{
                    setCNotify({title: 'Problem in updating Metadetails', Exp: 'on Server:SavedCourse', icon: 'iconoir:db-error'})
                })
            } else{
                store.currentEdits.put({type: `${useridstate}coverCurr`, value: imgSrc})
                .then(() => {
                    setCNotify({title: 'Metadetails Updated', Exp: 'on Local:CurrentCourse', icon: 'iconoir:db'})
                }).catch(()=> {
                    setCNotify({title: 'Problem in updating Metadetails', Exp: 'on Local:CurrentCourse', icon: 'iconoir:db-error'})
                })
            }
        }

        
    }

    const clearCoverImg = () => {
        setFiles([])
    }

    //   console.log(files.isArray)

    return(
        <Container>
            <Row>
                <Info variant="primaryTrans" size="50" icon="fluent:draw-image-20-filled">
                    <ul>
                        <li>Recommeded Size: Image file Max: 10mb</li>
                        <li>Recommeded Format: .jpg | .png | .webp</li>
                        <li>Image must be in 16:9 ratio</li>
                    </ul>
                </Info>
            </Row>
            <Row col={2}>
                <Col size={4}>
                    <Badge variants="primary" css={{marginLeft: '1rem'}}>Lecture Card Preview</Badge>
                    <PrevCard>
                        <CardVert ltitle={meta?.ltitle} stitle={meta?.stitle} subject={meta?.subject} cover={coverDet} files={files} />
                    </PrevCard>
                </Col>
                <Col size={6}>
                    {!coverDet && 
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <StyledDropZone>
                                {
                                    isDragActive ?
                                    <p>Drop the files here ...</p> :
                                    <div className='dropInstruct'>
                                        <Icon icon="fa-solid:long-arrow-alt-down" height="62" inline />
                                        <div className='d-flex flex-column ms-3'>
                                            <p>Drag &apos;n&apos; drop some picture here</p>
                                            <p>or click to select picture</p>
                                            <em>Only *.jpg,.png,.jpeg will be accepted</em>
                                        </div>
                                    </div>
                                }
                                
                            </StyledDropZone>
                        </div>
                    }
                    
                    <ProgressButton variants={coverDet ? "success" : "primary" } disabled={coverDet ? true : false} css={{marginTop: '1rem'}} onClick={() => uplFn()}>
                        {uplState === 2 ? 
                        <div className="line1">
                            <Icon icon="fluent:checkmark-circle-24-filled" inline /> <span>Uploaded</span>   
                        </div> : uplState === 1 ?
                        <div className="line1">
                            <Icon icon="line-md:uploading-loop" inline /> <span>Uploading</span>   
                        </div> : 
                        <div className="line1">
                            <Icon icon="fluent:cloud-arrow-up-28-filled" inline /> <span>Upload CoverImage</span>   
                        </div>
                        }
                    </ProgressButton>
                    {files.length > 0 && 
                        <Button variants={"danger"} onClick={() => clearCoverImg()} css={{marginTop: '1rem', display: 'flex', flexGrow: 1}}>
                            <Icon icon="pajamas:clear" style={{marginRight: '0.4rem'}} inline /><span>Clear Selection</span>
                        </Button>
                    }
                    
                    {coverDet &&
                        <Row justify="center" css={{marginTop: '2rem'}}>
                            <ImgDetails size={11}>
                                <span>Media ID</span>
                                <Badge variants="secondary">{coverDet.imgId}</Badge>
                            </ImgDetails>
                            <ImgDetails size={11} css={{marginBottom: '1.3rem'}}>
                                <span>Provider</span>
                                <Badge variants="secondary">{coverDet.provider === 'cf' ? 'native' : coverDet.provider}</Badge>
                            </ImgDetails>
                            <ImgDetails size={11}>
                                <span>File Name</span>
                                <Badge variants="secondary">{coverDet.lfile?.name}</Badge>
                            </ImgDetails>
                            <Col size={11}>
                                <Row justify="center">
                                    <ImgDetails size={11}>
                                        <span>Type</span>
                                        <Badge variants="secondary">{coverDet.lfile?.type}</Badge>
                                    </ImgDetails>
                                
                                    <ImgDetails size={11}>
                                        <span>Size</span>
                                        <Badge variants="secondary">{prettyBytes(coverDet.lfile?.size)}</Badge>
                                    </ImgDetails>
                                    <ImgDetails size={11}>
                                        <span>Last Modified</span>
                                        <Badge variants="secondary">{coverDet.lfile?.lm}</Badge>
                                    </ImgDetails>
                                </Row>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        </Container>
    )
}

export default CoverImage