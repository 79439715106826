import {styled} from '../../../stitches.config'


export const Container = styled('div', {
    display: 'grid',
    gridTemplateColumns: '20vw 1fr',
    gridTemplateRows: '1fr',
    gap: '0px 0px', 
    gridTemplateAreas: "'SideNavGrid' 'BodyGrid'",
    height: '100%',
    width: '100%',
    overflow: 'hidden'
})

export const SideNavGrid = styled('div', {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    padding: '5px 0px 5px 5px',
    overflow: 'hidden'
})

export const BodyGrid = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: 'calc(100vh - 5px)'
})



export const NavbarGrid = styled('div', {
    gridArea: 'NavGrid',
    padding: '5px 5px 0px 5px'
})


export const MainGrid = styled('main', {
    height: '100%',
    padding: '0.4rem 0.6rem',
    overflow: 'hidden'
})