import { styled } from "../../../stitches.config"

export const CardHorizontal = styled("div", {
    width: 'auto',
    borderRaidus: 15,
    background: '$cardBg',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 15
})

export const PrevCardMeta = styled("div", {
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none',
    

    '& p': {
        fontWeight: 600
    },

    '& span': {
        opacity: '0.8',
        fontSize: 10
    },

    

})

export const ThumbCont = styled("div", {
    display: 'flex',
    position: 'relative',
    fontSize: 12,

    '& .sub': {
        position: 'absolute',
        bottom: '10px',
        left: '10px',
        background: '#000000',
        color: '#fff',
        borderRadius: 10,
        paddingInline: '0.6rem',
        paddingBlock: '0.1rem'
    },

    '& .dur': {
        position: 'absolute',
        right: '10px',
        bottom: '10px',
        background: '#000000',
        color: '#fff',
        borderRadius: 10,
        paddingInline: '0.4rem'
    }
})