import {Badge, Button, Separator} from "../UI"
import { useState, useReducer } from "react"
import { Navbar, NavContent, PageTitle } from "./navbar.style"
import NotifySpace from "./notifiSpace"
import { Icon } from '@iconify/react'
import { useLocation } from "react-router-dom"
import { ClearAll, DeleteLecture, PublishLecture, PublishSaved, SaveLocally } from "./btnFunc"
import {Prompt} from "../UI"
import { useRecoilValue } from "recoil"
import { dynamicNavs } from "../../states/atoms"
import { useEffect } from "react"

const findPage = (pageLoc, current) => {
    return pageLoc = current
} 

const NavbarTop = () => {
    // const { theme, setTheme } = useTheme("light");
    // const setPrompt = useSetRecoilState(viewPrompt)
    const userDet = JSON.parse(sessionStorage.getItem('user'))
    const instiDet = JSON.parse(sessionStorage.getItem('insti'))
    const dynamicNavState = useRecoilValue(dynamicNavs)
    const {pathname} = useLocation()
    const [PromptState, setPromptState] = useState(false)
    const [PromptFor, setPromptFor] = useState("")
    const [pageLoc, setPageLoc] = useReducer(findPage, "Dashboard")

    useEffect(() => {
        console.log(pathname)
            if(pathname === '/') setPageLoc("Dashboard")
            else if(pathname === '/lecture/add') setPageLoc("Add Lecture")
            else if(pathname.includes("/lecture/saved")) setPageLoc("Saved Lecture")
            else if(pathname.includes("/lecture/draft")) setPageLoc("Drafted Lecture")
            else if(pathname.includes("/lecture/manage")) setPageLoc("Published Lecture")
            else setPageLoc("")
    }, [pathname])

    const toggleTheme = () => {
        const currTheme = localStorage.getItem('theme')
        const theme = currTheme === 'dark-theme' ? 'light-theme' : 'dark-theme'
        if(theme === 'dark-theme'){
            localStorage.setItem('theme', theme)
            document.body.classList.remove('light-theme')
            document.body.classList.add('dark-theme')
            document.body.style.colorScheme = "dark"
        }else{
            localStorage.setItem('theme', theme)
            document.body.classList.remove('dark-theme')
            document.body.classList.add('light-theme')
            document.body.style.colorScheme = "light"
        }
    }

    const openPrompt = (promptFor) => {
        setPromptState(!PromptState)
        setPromptFor(promptFor)
    }

    console.log(`Dynamic Nav State: ${dynamicNavState}`)

    return(
        <Navbar>
            <NavContent>
                <>
                    <PageTitle>{pageLoc}</PageTitle>
                    <Button variants="themedHover" hide={dynamicNavState === 2 ? false : true} title="Save Local" onClick={() => openPrompt("save")}>
                        <Icon icon="fluent:save-28-filled" width="22" height="22" style={{marginRight: 5}} />
                        <div>
                            <span>Save Local</span>
                        </div>
                    </Button>
                    <Button variants="themedHover" hide={dynamicNavState === 2? false : true} title="Clear All" onClick={() => openPrompt("clear")}>
                        <Icon icon="fluent:delete-28-filled" width="22" height="22" style={{marginRight: 5}} />
                        <div>
                            <span>Clear All</span>
                        </div>
                    </Button>
                    <Button variants="themedHover" hide={dynamicNavState === 2 ? false : true} title="Publish to Genosis" onClick={() => openPrompt("publish")}>
                    {/* <Button variants="themedHover" title="Publish to Genosis" onClick={() => handleStartUpload()}> */}
                        <Icon icon="fluent:cloud-arrow-up-20-filled" width="22" height="22" style={{marginRight: 5}} />
                        <div>
                            <span>Publish</span>
                        </div>
                    </Button>
                    <Button variants="themedHover" hide={dynamicNavState===3 ? false : true} title="Delete this Lecture" onClick={() => openPrompt("deleteLecture")}>
                        <Icon icon="fluent:delete-28-filled" width="22" height="22" style={{marginRight: 5}} />
                        <div>
                            <span>Delete Lecture</span>
                        </div>
                    </Button>
                    <Button variants="themedHover" hide={dynamicNavState === 3 ? false : true} title="Publish to Genosis" onClick={() => openPrompt("publishSaved")}>
                    {/* <Button variants="themedHover" title="Publish to Genosis" onClick={() => handleStartUpload()}> */}
                        <Icon icon="fluent:cloud-arrow-up-20-filled" width="22" height="22" style={{marginRight: 5}} />
                        <div>
                            <span>Publish</span>
                        </div>
                    </Button>
                </>
            </NavContent>
            <NavContent>
                <NotifySpace />
                {/* <Button variants="themedHover" onClick={toggleTheme} title="Theme Toggle">
                    <Icon icon="fluent:weather-moon-16-regular" width="22" height="22" />
                </Button> */}
                <Button variants="themed">
                    <Icon icon="ph:user-circle-light" width="32" height="32" style={{marginRight: 5}} />
                    <div className="twoLine">
                        <span>{userDet.name}</span>
                        <span>{userDet.field}</span>
                    </div>
                </Button>
            </NavContent>
            <NavbarPrompts promptFor={PromptFor} show={PromptState} setPromptState={setPromptState} />
        </Navbar>
    )
}

const NavbarPrompts = ({promptFor, show, setPromptState}) => {
    console.log(show)
    return(
        <Prompt.Container title="Publish to Genosis" show={show} setPromptState={setPromptState}>
            {promptFor === 'publish' ? <p>Once the Lecture and quiz is published. it will marked as Draft, after admin review, it will published!</p> :
            promptFor === 'clear' ? <>
            <p>It will clear currently working lecture data.</p>
            <p>It will delete uploaded Cover Image and Lecture Video</p>
            </> : 
            promptFor === 'save' ? <>
            <p>It will save the currently editing lecture to browser's storage for later edits.</p>
            <p>This won't synced with cloud server.</p>
            </> : 
            promptFor === 'deleteLecture' ? <>
                <p>It will clear saved lecture lecture data permenently.</p>
                <p>It will delete uploaded Cover Image and Lecture Video</p>
            </> :
            promptFor === 'publishSaved' && <>
                <p>Once the Lecture and quiz is published. it will marked as Draft, after admin review, it will published!</p>
            </>
            }
            
            {
            promptFor === 'publish' ? <PublishLecture setPromptState={setPromptState} /> : 
            promptFor === 'clear' ? <ClearAll setPromptState={setPromptState} /> :
            promptFor === 'save' ? <SaveLocally setPromptState={setPromptState} /> : 
            promptFor === 'deleteLecture' ? <DeleteLecture setPromptState={setPromptState} /> :
            promptFor === 'publishSaved' && <PublishSaved setPromptState={setPromptState} />
            }
            
        </Prompt.Container>
    )
}


export default NavbarTop