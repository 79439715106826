import { styled } from "@stitches/react";
import {Card} from "../../components/UI"

// Card.toString = () => '.right-arrow';

export const CardSpl = styled(Card.Body, {

    '& span': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '&:first-child':{
            fontWeight: 700,
            fontSize: '2rem'
        }
        
    }
})

export const MetricsDash = styled("div", {
    background: 'linear-gradient(93.16deg, #E3FDF5 25.92%, #FFE6FA 89.35%)',
    color: '#2d2d2d',
    padding: '0.5rem',
    position: 'relative',
    display: 'flex',
    minWidth: '60vw',
    width: '100%',
    height: 'auto',
    wordWrap: 'break-word',
    backgroundClip: 'border-box',
    borderRadius: 5
})

export const Metric = styled("a", {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    borderRight: '1px dashed rgba(0, 0, 0, 0.25)',
    color: '#222222',
    textDecoration: 'none',

    '& span:first-child': {
        fontWeight: 600,
        fontSize: '2.5rem'
    },
    '& span:last-child': {
        fontWeight: 400,
        fontSize: '1rem'
    },
})

export const TutorInfo = styled("div", {
    display: 'flex',
    flexDirection: 'column',
    padding: '0.5rem 1rem',
    textAlign: 'right',
    marginLeft: 'auto',

    '& span:first-child': {
        fontWeight: 500,
        fontSize: '2rem',
    },
    '& span:nth-child(2)': {
        fontSize: '1rem',
        fontWeight: 500,
        opacity: 0.5
    },
    '& span:last-child': {
        fontSize: '1rem'
    }
})