import { Route, Routes, Navigate, Outlet } from 'react-router-dom'
import Layout from './components/layout'

import Dashboard from './pages/dashboard'
import Addcoursepg from './pages/addCoursePg'
import CourseDraft from './pages/courseDraft'
import LoginPg from './pages/login'
import SavedLecturesPG from './pages/savedLectures'
import NotReadyPg from './pages/notready'
import ManageCourse from './pages/manageCourse'

const App = () => {

  return (
    <Routes>
      {/* <Route path="/" element={<Layout />}> */}
      <Route path="/" element={<ProtectedRoute><Layout /></ProtectedRoute>}>
        <Route index path="" element={<Dashboard />} />
        <Route path="lecture">
          <Route path="add" element={<Addcoursepg />} />
          <Route path="saved">
            <Route path="" element={<SavedLecturesPG />} />
            <Route path=":id" element={<Addcoursepg />} />
          </Route>
          <Route path="draft" element={<CourseDraft />} />
          <Route path="manage" element={<ManageCourse/>} />
        </Route>
        <Route path="student">
          <Route path="add" element={<NotReadyPg />} />
          <Route path="blkadd" element={<NotReadyPg />} />
          <Route path="modify" element={<NotReadyPg />} />
        </Route>
        
      </Route>
      <Route path="login" element={<LoginPg />} />
      {/* <Route path="loginProgress" element={<LoginProgress />} /> */}
      <Route component={Error} />
    </Routes>
  )
}

const ProtectedRoute = ({ redirect= '/login', children }) => {
  const user = sessionStorage.getItem('haha')
  if (!user) {
    console.log('NOt Authenticated, Redirecting to Login Page')
    return <Navigate to={redirect} replace />;
  }

  return children || <Outlet />;
};

export default App
