import { Container, NavbarGrid, SideNavGrid, MainGrid, BodyGrid } from "./AppGrid.style"
import { Outlet } from 'react-router-dom'
import NavbarTop from "../navbar/index.jsx"
import SideNav from "../sidenav"
import { useRecoilValue } from 'recoil'
import { NavSizeState } from '../../states/atoms'

import { darkTheme } from "../../styles/theme"


const Layout = () => {
const NavSize = useRecoilValue(NavSizeState)
console.log(NavSize === 2 ? 'Expanded Nav' : 'collapsed Nav')

  return (
    <Container css={{gridTemplateColumns: NavSize === 2 ? '20vw 1fr' : '10vw 1fr'}}>
        <SideNavGrid css={{width: NavSize === 2 ? '20vw' : '10vw'}}>
            <SideNav />
        </SideNavGrid>
        <BodyGrid>
            <NavbarGrid>
                <NavbarTop />
            </NavbarGrid>
            
            <MainGrid>
                <Outlet />
            </MainGrid>
        </BodyGrid>
    </Container>
  )
}

export default Layout