import { globalCss } from "../../stitches.config";

export const globalStyles = globalCss({
    '*,*::before,*::after': { 
        margin: 0, 
        padding: 0,
        '-moz-box-sizing': 'border-box',
        '-webkit-box-sizing': 'border-box', 
        boxSizing: 'border-box'
     },
     '::-webkit-scrollbar':{
        width: 0,
        background: 'transparent'
    },
    body: {
        margin: 0,
        padding: 0,
        fontFamily: "$system",
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        background: '$background',
        color: '$foreground',
        overflow: 'hidden'
    },
    code: {
        fontFamily: `source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace`
    },
    '*::selection': {
        background: '$btnTransBg',
        color: '$foreground',
      },

    '.twoLine': {
        textAlign: 'center',
        ['& span:first-child']: {
            fontSize: 12,
            letterSpacing: 0.5,
            lineHeight: '16px'
        },
        ['& span:nth-child(2)']: {
            fontSize: 12,
            opacity: 0.4,
            letterSpacing: 0.5

        }
    }
  });