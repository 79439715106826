import { Container, Row, Col, Table, Badge } from "../../components/UI"
import { useRecoilValue } from "recoil"
import { useState, useEffect, useLayoutEffect } from "react"
import { useNavigate } from "react-router-dom"
import { dynamicNavs, UserIDSelector } from "../../states/atoms"
import { getSavedData } from "./getData"
import { store } from "../../states/db"
import { useSetRecoilState } from "recoil"
import { PageContainer } from "../addCoursePg/addcoursepg.style"
import CardHori from "../../components/lectureCard/cardHori"

const SavedLecturesPG = () => {
    const userid = useRecoilValue(UserIDSelector)
    const setDynamicNav = useSetRecoilState(dynamicNavs)
    const [savedData, SetSavedData] = useState(null)
    const location = useNavigate()
    useLayoutEffect(() => setDynamicNav(1))

    useEffect(()=> {
        const fetchSaved = async() => {
            const res = await store.savedCourses.filter((c) => {
                return c.userid === userid
            }).reverse().toArray()
            console.log(await res)
            SetSavedData(await res)
        }
        fetchSaved()
    }, [])

    const openSaved = (id) => {
        console.log('open saved'+ id)
        location(`${id}`)
    }
    
    if(savedData)
    return(
        <PageContainer>
            <Row col="3">
                {savedData.map((row) => {
                    return(
                        <Col key={row.id} onClick={() => openSaved(row.id)}>
                            <CardHori ltitle={row.meta.ltitle} stitle={row.meta.stitle} subject={row.meta.subject} cover={row.cover} quizM={row.quiz.mode} quiz={row.quiz.length} />
                        </Col>
                    )
                })}
            </Row>
        </PageContainer>
    )
}

export default SavedLecturesPG