import { styled } from "../../../stitches.config"
import { Button } from "../UI"
import { Icon } from "@iconify/react"
import {NavLink} from 'react-router-dom'


export const SideNavDiv = styled('nav', {
    // borderRight: '$gray4 1.4px solid',
    // height: '100%',
    borderRadius: 10,
    background: '$navbarBg',
    height: 'calc(100vh - 5px - 5px)'
})

export const NavBrand = styled('div', {
    fontWeight: 700,
    letterSpacing: 1,
    fontSize: '1.4rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    position: 'relative',
    width: '100%',
    marginTop: '0.4rem',

    '& div': {
        display: 'flex',
        flexDirection: 'column'
    },
    
    '& span:first-child': {
        fontSize: 10,
        color: '$amber9'
    }
})

export const NavBrandCont = styled('div', {
    display: 'flex',
    flexDirection: 'space-between',
    alignItems: 'center',
    padding: '0.2rem 1rem 0rem',
    userSelect: 'none',
    background: 'transparent',
})

export const ToggleBtnDiv = styled('div', {
    position: 'relative',
    display: 'flex',

    [`& ${Button}`]: {
        zIndex: 9999,
        top: 0,
        bottom: 0,
        right: 10
    }

})

export const SideNavContent = styled('div', {
    padding: '0rem 1rem',
    height: 'calc(100% - 70px)',
    overflowY: 'scroll'
})

export const SideNavMenu = styled(NavLink, {
    fontSize: '1rem',
    fontWeight: '400',
    display: 'flex',
    transition: 'background 200ms ease-in-out, color 200ms ease-in-out',
    userSelect: 'none',
    padding: '1rem',
    textDecoration: 'none',
    borderRadius: '10px',
    color: '$foreground',
    marginTop: 2,

    '&:hover, &.active': {
        background: '$btnTransBg',
        color: '$btnTransColor'
    },

    variants: {
        size: {
            2: {
                flexDirection: 'row',
                justifyContent: 'start',
                alignItems: 'center',

                '& .iconify': {
                    marginRight: '0.4rem',
                    transition: 'width 0.1s ease-in-out'
                },
                
                '& span': {
                    fontSize: '1rem',
                    transition: 'font-size 0.1s ease-in-out'
                },
            },
            1: {
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',

                '& .iconify': {
                    marginRight: '0rem',
                    transition: 'width 0.1s ease-in-out'
                },

                '& span': {
                    fontSize: '0.8rem',
                    transition: 'font-size 0.1s ease-in-out'
                },
            }
        }
    }
})

export const SideNavMenuTitle = styled('span', {
    fontSize: '0.8rem',
    fontWeight: '200',
    letterSpacing: 1,
    opacity: '0.5',
    marginTop: '1rem',
    userSelect: 'none'
})