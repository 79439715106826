import { useLiveQuery } from "dexie-react-hooks"
import { useLayoutEffect } from "react"
import Helmet from "react-helmet"
import { useSetRecoilState } from "recoil"
import { Container, Row, Col, Card } from "../../components/UI"
import { dynamicNavs } from "../../states/atoms"
import {store} from '../../states/db'
import { CardSpl, Metric, MetricsDash, TutorInfo } from "./dash.style"
import { Link } from "react-router-dom"
import { Icon } from "@iconify/react"
import { gql, useQuery } from '@apollo/client'; 

const getMetaFac = gql`
  query GetFacMeta($facId: String!) {
    GetFacMeta(facId: $facId) {
      cdraft
      cpub
      facId
      id
      instiId
      scloud
    }
  }
`
const Dashboard = () => {
    const setDynamicNav = useSetRecoilState(dynamicNavs)
    const scCount = useLiveQuery(async() => store.savedCourses.count())
    const ccCount = useLiveQuery(async() => store.currentEdits.count())
    const {name, id, userType, field} = JSON.parse(sessionStorage.getItem('user'))
    const {name: instiname} = JSON.parse(sessionStorage.getItem('insti'))

    const { loading, error, data } = useQuery(getMetaFac, {variables: {facId: id}});

    useLayoutEffect(() => {
      setDynamicNav(1)
    })

    if(loading){
      return(<span>Loading ...</span>)
    } else if(data){

    
    return (
      <Container>
        <Helmet>
          <title>GenFac - Playwithbot</title>
          <meta name="description" content="Genosis Faculty App for managing course content" />
          <link rel="icon" href="/favicon.ico" />
        </Helmet>
  
        <>
          <Row col="12" justify="start" align="center">
            
            <Col>
              <MetricsDash>
                <Metric as={Link} to="/lecture/manage">
                  <span>{data?.GetFacMeta?.cpub || scCount}</span>
                  <span>Published</span>
                </Metric>
                <Metric as={Link} to="/lecture/draft">
                  <span>{data?.GetFacMeta?.cdraft || scCount }</span>
                  <span>Drafted Lectures</span>
                </Metric>
                <Metric as={Link} to="/lecture/saved">
                  <span>{scCount}</span>
                  <span>Saved Lectures</span>
                </Metric>
                {
                  ccCount > 0 &&
                  <Metric as={Link} to="/lecture/add">
                      <strong><Icon className='me-1' icon="ant-design:info-circle-outlined" inline height={24} /> Pending</strong>
                      <span>1 Unsaved content</span>
                      <span>Click to open <Icon icon="bi:arrow-right-short" inline /></span>
                  </Metric>
                }
                <TutorInfo>
                    <span>{name}</span>
                    <span>{field} {userType}</span>
                    <span>{instiname}</span>
                </TutorInfo>
              </MetricsDash>
              
                        
            </Col>
          </Row>
        </>
        
      </Container>
    )
   }
  }

export default Dashboard