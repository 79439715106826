import { styled } from "../../../stitches.config";

export const Navbar = styled('nav', {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    background: '$navbarBg',
    padding: '0rem 0rem 0rem 1rem',
    margin: 0,
    alignItems: 'center',
    height: 60,
    borderRadius: 10
})

export const PageTitle = styled('span', {
    fontWeight: 40,
    fontSize: '1.3rem',
    marginRight: '1.2rem'
})

export const NavContent = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .twoLine': {
        textAlign: 'left'
    }
})

