import { Icon } from '@iconify/react';
import { styled } from '../../../stitches.config';
import Button from '../UI/button';

const NotifyBar = styled('button', {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    
    [`& ${Icon}`]: {
        mX: 10
    },

    [`& .title`]: {
        fontWeight: 400,
        fontSize: '12px',
        whiteSpace: 'nowrap'
    }
})

const NotifySpace = () => {
    return(
        <Button variants="themedHover" title="Notification">
            <Icon icon="ion:notifications-outline" inline={true} width="22" />
        </Button>
    )
}

export default NotifySpace