import { styled } from "../../../stitches.config"
import { ErrorInput } from "./utils"
import { forwardRef } from "react"

const SelectStyled = styled("select", {
    appearance: 'none',
    backgroundColor: '$inputBg',
    color: '$color',
    border: 'none',
    padding: '0rem 1rem',
    width: '100%',
    maxWidth: '30vw',
    outline: 'none',
    height: '3rem',
    fontSize: 16,
    borderRadius: 5
})

const SelectDiv = styled("div", {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginBottom: '1rem',

    '& label': {
        marginBottom: '0.6rem',
        fontSize: '0.9rem',
        letterSpacing: '0.6px'
    }
})

const Select = forwardRef(({children, onChange, name, onBlur, label, errors, isDirty, ...props}, ref) => (
        <SelectDiv>
            <label>{label}</label>
            <SelectStyled name={name} onChange={onChange} onBlur={onBlur} ref={ref} {...props}>
                {children}
            </SelectStyled>
            {errors != 'false' && <ErrorInput>{errors}</ErrorInput>}
        </SelectDiv>
    ) )

export default Select