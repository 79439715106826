import { useState, useRef } from "react"
import {Modal, Container, Row, Col, Button, InputGrp, Radio} from "../../../components/UI"
import { viewQuizState, UserIDSelector, viewQuizId, CurrentNotification } from "../../../states/atoms"
import { store } from "../../../states/db"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { useLiveQuery } from "dexie-react-hooks"
import { useForm } from "react-hook-form"
import {useDropzone} from 'react-dropzone'
import { Icon } from "@iconify/react"
import {StyledDropZone} from '../uploadVideo/videoUpl.style'
import { ThumbsContainer, ThumbInner, Thumb, Img, QuizInputGrp, VerticalButton, Que, Ans, ClearBtn, ImgPreview, QuizList } from "./quizSec.style"
import imageUpload from '../../../components/upload/imgUpl'
import {useDebounce} from 'use-debounce'

const QuizModal = () => {
    const [show, setShow] = useRecoilState(viewQuizState)
    const childRef = useRef()
    const { register, handleSubmit, reset: reset1 } = useForm();
    const { register: register2, getValues, reset: reset2 } = useForm();
    const useridstate = useRecoilValue(UserIDSelector)
    const [quizId, setQuizId] = useRecoilState(viewQuizId);
    const setCNotify = useSetRecoilState(CurrentNotification)
    const [files, setFiles] = useState([]);
    const [dropkey, setDropKey] = useState(0);
    const [debounceKey] = useDebounce(dropkey, 1000);


    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg','.jpeg'],
            'image/webp': ['.webp']
        },
        onDrop: acceptedFiles => {
            
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));

        }
    });

    const closeModalBtn = () => {
        setShow(!show)
    }

    const resetForm = () => {
        reset1({
            quiz: {
                q: '',
                ans: '',
                opt1: '',
                opt2: '',
                opt3: '',
                opt4: '',
                mark: '',
                timer: ''
            }
        })
        setFiles([])
        setDropKey(dropkey + 1)
    }

    // const handleClose = (is) => {
    //     const canClose = is ? true : window.confirm('Please save the Quiz!!, \nif not saved, please give cancel')
    //     if(canClose){
    //         setQuizId(null)
    //         setShow(false);
    //     }
        
    // }

    const startImgUpld = async(data) => {
        const {quiz} = data
        console.log(data)
    
        if(files.length > 0){
            console.table(files[0])
            const formData = new FormData();
            formData.append('file', files[0]);

            const URLdata = await imageUpload()
            const res = await fetch(URLdata, {
                method: 'post',
                body: formData,
            })

            const {result: {id:imgId, variants:imgvar}} = await res.json()
            Object.assign(quiz, {imgSrc :{imgId, imgvar}})
        }
        

        // const url = await res.json()

        if(quizId.isSaved){
            const exist = await store.savedQuiz.get(quizId.qid)
            if(exist instanceof Object){
                await store.savedQuiz.where('uuid').equals(quizId.qid).modify(x => x.value.push(quiz)).then(() => {
                    setCNotify({title: `Quiz Added ${quizId.qid}`, Exp: 'on Local:savedQuiz', icon:'bi:list-check'})
                }).then(
                    () => resetForm()
                )
            } else {
                await store.savedQuiz.add({uuid: quizId.qid, value:[quiz]}).then(() => {
                    setCNotify({title: `Quiz Added ${quizId.qid}`, Exp: 'on Local:savedQuiz', icon:'bi:list-check'})
                }).then(
                    () => resetForm()
                )
            }
        } else{
            const exist = await store.quizCurr.get(quizId.qid)
            if(exist instanceof Object){
                await store.quizCurr.where('uuid').equals(quizId.qid).modify(x => x.value.push(quiz)).then(() => {
                    setCNotify({title: `Quiz Added ${quizId.qid}`, Exp: 'on Local:savedQuiz', icon:'bi:list-check'})
                }).then(
                    () => resetForm()
                )
            } else {
                await store.quizCurr.add({uuid: quizId.qid, value:[quiz]}).then(() => {
                    setCNotify({title: `Quiz Added ${quizId.qid}`, Exp: 'on Local:savedQuiz', icon:'bi:list-check'})
                }).then(
                    () => resetForm()
                )
            }
        }
        
        // setExist(true)
    }

    const setQname = async() => {
        const quizMeta = getValues('quizMeta')
        console.log(quizMeta)

        if(quizId.isSaved){
            const exist = await store.savedQuiz.get(quizId.qid)
            await store.savedQuiz.where(`type`).equals(`${useridstate}quizCurr`).modify(
                x => Object.assign(x.value, x.value.map(y => y.qid === quizId.qid ? {...y, qname: quizMeta.title} : {...y}))
            )
        } else{
            const exist = await store.currentEdits.get(quizId.qid)
                await store.currentEdits.where(`type`).equals(`${useridstate}quizCurr`).modify(
                    x => Object.assign(x.value, x.value.map(y => y.qid === quizId.qid ? {...y, qname: quizMeta.title} : {...y}))
                )
            }
        }        
    

     
    // const saveQuiz = async() => {
    //     // await store.currentEdits.add([quizId], 'quizCurr')
    //     console.group('Running saveQuiz()')
    //     if(quizId.isSaved){
    //         const exist = await store.savedCourses.get(quizId.scid)
    //         console.log(exist)
    //         if(await exist?.quiz?.includes(quizId.qid)){
    //             console.log('%cAlready Saved, so returning...',"font-weight: bold; color: black; background: white; border: 1px dashed #4d4d4d; padding: 2px 5px; border-radius: 10px;")
    //         }
    //         else if(await exist?.quiz instanceof Array){
    //             console.log('Table already exist, so updating')

    //             // const merged = exist.push([quizId.qid])
    //             await store.savedCourses.where('id').equals(quizId.scid).modify(x => x.quiz.push(quizId.qid) )

    //         }else{
    //             console.log('Table is empty, so adding new entry')
    //             await store.savedCourses.where('id').equals(quizId.scid).modify(x => x.quiz[quizId.qid])
    //             // await store.savedCourses.put({type: 'quizCurr', value: [quizId.qid]})
    //         }
    //     }
    //     //  else{
    //     //     const exist = await store.currentEdits.get({type: `${useridstate}quizCurr`})
    //     //     if(await exist?.value.includes(quizId.qid)){
    //     //         console.log('%cAlready Saved, so returning...',"font-weight: bold; color: black; background: white; border: 1px dashed #4d4d4d; padding: 2px 5px; border-radius: 10px;")
    //     //     }
    //     //     else if(await exist instanceof Object){
    //     //         console.log('Table already exist, so updating')
    
    //     //         // const merged = exist.push([quizId.qid])
    //     //         await store.currentEdits.where('type').equals(`${useridstate}quizCurr`).modify(x => x.value.push(quizId.qid) )
    
    //     //     }else{
    //     //         console.log('Table is empty, so adding new entry')
    //     //         await store.currentEdits.put({type: `${useridstate}quizCurr`, value: [quizId.qid]})
    //     //     }
    //     // }
    //     console.groupEnd()
    //     closeModalBtn()
        
    // }

    

    const clearCoverImg = (e) => {
        e.stopPropagation()
        setFiles([])
        
    }

    const thumbs = files.map(file => (
        <Thumb key={file.name}>
          <ThumbInner>
            <Img src={file.preview} alt="quiz-img" width="200" height="200" />
          </ThumbInner>
          <ClearBtn variants="secondary" onClick={(e) => clearCoverImg(e)}>x</ClearBtn>
        </Thumb>
      ));
    

    return(
        <Modal.Container vAlign="startCenter" width="full" title="Edit Quiz" show={show} closeModalBtn={closeModalBtn} toolBar>
            <Modal.Body>
                <Container>
                    <Row css={{justifyContent: 'space-between'}}>
                        <Col size={4}>
                            <InputGrp type="text" label="Panel Name" autoComplete="disabled" name="quizPanelName" {...register2("quizMeta.title", {required: true, onBlur: () => setQname()})} />
                            <h2>Add Multi Choice Question</h2>
                            <form id="quizForm" onSubmit={handleSubmit(startImgUpld)}>
                                <InputGrp type="text" name="q" label="Question ?" autoComplete="disabled" {...register("quiz.q")} placeholder="Which of these planets is the smallest?" css={{maxWidth: '100%'}} />
                                <Row col={2}>
                                    <QuizInputGrp css={{padding: '0rem 1rem'}}>
                                        <Radio className="optRadio" name="ans" value="opt1" {...register("quiz.ans")} >Answer</Radio>
                                        <InputGrp type="text" className="ans" name="opt1" label="Option 1" autoComplete="disabled" placeholder="Jupiter" {...register("quiz.opt1")} />
                                    </QuizInputGrp>
                                    <QuizInputGrp css={{padding: '0rem 1rem'}}>
                                        <Radio className="optRadio" name="ans" value="opt2" {...register("quiz.ans")} >Answer</Radio>
                                        <InputGrp type="text" name="opt2" label="Option 2" autoComplete="disabled" placeholder="Earth" {...register("quiz.opt2")} />
                                    </QuizInputGrp>
                                    <QuizInputGrp css={{padding: '0rem 1rem'}}>
                                        <Radio className="optRadio" name="ans" value="opt3" {...register("quiz.ans")} >Answer</Radio>
                                        <InputGrp type="text" name="opt3" label="Option 3" autoComplete="disabled" placeholder="Mercury" {...register("quiz.opt3")} />
                                    </QuizInputGrp>
                                    <QuizInputGrp css={{padding: '0rem 1rem'}}>
                                        <Radio className="optRadio" name="ans" value="opt4" {...register("quiz.ans")} >Answer</Radio>
                                        <InputGrp type="text" name="opt4" label="Option 4" autoComplete="disabled" placeholder="Uranus" {...register("quiz.opt4")} />
                                    </QuizInputGrp>
                                </Row>
                                <h3 style={{marginTop: '1.4rem'}}>Add Mark and Timing</h3>
                                <Row>
                                    <Col css={{padding: '0rem 1rem'}}>
                                        <InputGrp type="number" name="qmark" label="Mark" placeholder="2" autoComplete="disabled" {...register("quiz.mark")} />
                                    </Col>
                                    <Col css={{padding: '0rem 1rem'}}>
                                        <InputGrp type="number" name="sec" label="Time in Seconds" placeholder="60" autoComplete="disabled" {...register("quiz.timer")} />
                                    </Col>
                                </Row>
                                <h3 style={{marginTop: '1.4rem', marginBottom: '1rem'}}>Add Image related to question</h3>
                                <Row>
                                    <Col css={{padding: '0rem 1rem'}} {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <StyledDropZone key={dropkey}>
                                            {
                                                isDragActive ?
                                                <p>Drop the files here ...</p> :
                                                <div className='dropInstruct'>
                                                    <Icon icon="fa-solid:long-arrow-alt-down" height="62" inline />
                                                    <div className='d-flex flex-column ms-3'>
                                                        <p>Drag &apos;n&apos; drop some picture here</p>
                                                        <p>or click to select picture</p>
                                                        <em>Only *.jpg,.png,.jpeg will be accepted</em>
                                                    </div>
                                                </div>
                                            }
                                            <ThumbsContainer>
                                                {thumbs && thumbs}
                                            </ThumbsContainer>
                                        </StyledDropZone>
                                    </Col>
                                </Row>
                            </form>
                        </Col>
                        <Col size={0.5} css={{display: 'flex', justifyContent: 'center'}}>
                            <VerticalButton variants="primary" type="submit" form="quizForm">
                                
                               <p><Icon icon="ic:round-double-arrow" /> Add Question to Quiz<Icon icon="ic:round-double-arrow" /></p> 
                               
                            </VerticalButton>
                        </Col>
                        <Col size={7} style={{borderLeft: '1px solid gray9'}}>
                            {
                                quizId && <Existing uuid={quizId.qid} saved={quizId.isSaved}  />
                            }
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal.Container>
    )
}

const Existing = ({uuid, saved}) => {
    const quizId = useRecoilValue(viewQuizId)

    console.log(uuid, saved)
    const list = useLiveQuery(
        () => uuid && saved ? store.savedQuiz.get(uuid) : store.quizCurr.get(uuid)
    )

    const removeQuiz = async(e, index) => {
        e.stopPropagation()
        await store.quizCurr.where('uuid').equals(quizId.qid).modify(x => x.value.splice(index, 1))
    }
    // console.log(list[0])
    if (!list) return null; // Still loading.
    return(
        <div>
            {
                list.value.map((it, index) =>
                <QuizList key={index}>
                    <Col size={3}>
                        {it.imgSrc && <ImgPreview src={it.imgSrc.imgvar[1]} alt="quiz" width="200" height="200" />}
                    </Col>
                    <Col size={8} css={{margin: '0rem 0rem 1rem 1rem'}}>
                        <Row>
                            <Que as={Col}>
                                {it.q}
                                <small>{`${it.mark}m - ${it.timer}sec`}</small>
                            </Que>
                            
                        </Row>
                        <Row col={2}>
                            <Col><Ans ans={it.ans === 'opt1'}>{it.opt1}</Ans></Col>
                            <Col><Ans ans={it.ans === 'opt2'}>{it.opt2}</Ans></Col>
                            <Col><Ans ans={it.ans === 'opt3'}>{it.opt3}</Ans></Col>
                            <Col><Ans ans={it.ans === 'opt4'}>{it.opt4}</Ans></Col>
                        </Row>
                        <div className="action">
                        <Button className="remove" variants="secondary" onClick={(e) => removeQuiz(e, index)} ><Icon icon="carbon:trash-can" inline /></Button>
                    </div>
                    </Col>
                    
                </QuizList>)
            }
        </div>
    )
}

export default QuizModal