const createRandomHex = () => {
    let hexCode = "";
    const hexValues = "0123456789abcdef";
  
    for (let i = 0; i < 6; i++) {
      hexCode += hexValues.charAt(Math.floor(Math.random() * hexValues.length));
    }
    return hexCode;
}

export default createRandomHex