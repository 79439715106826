import { styled } from "../../../../stitches.config";
import { Button, Col, Row } from "../../../components/UI";

export const QuizBtn = styled(Col, {
    maxWidth: '100px',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    background: '$cardBg',
    border: '1px solid $gray1',
    margin: '0.6rem 1rem',
    padding: '1rem',
    borderRadius: 5,
    position: 'relative',

    '&:hover': {
        background: '$gray1',
        cursor: 'pointer'
    }
})

export const DeleteBtn = styled(Button, {
    position: 'absolute',
    top: '-0.8rem',
    right: '-0.8rem',
    color: '$red9',
    borderRadius: 20,

    '&:hover': {
        background: '$red9',
        color: '#000000'
    }
})

export const AddQuizBtn = styled(Button, {
    width: 100,
    height: 100,
    fontSize: '1rem',
    margin: '0.6rem',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    

    '& span': {
        fontSize: '14px',
        wordWrap: 'break-word'
    }
})

export const ThumbsContainer = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: '1rem',
    justifyContent: 'center'
})

export const Thumb = styled('div', {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid $gray4',
    margin: '1rem',
    width: '100%',
    height: '100%',
    padding: 4,
    position: 'relative'
})

export const ClearBtn = styled(Button, {
    position: 'absolute',
    top: -10,
    right: -10,
    paddingInline: '1rem',
    borderRadius: '1.5rem',

    '&:hover':{
        background: '$red9',
        color: '#111111'
    }
})

export const ThumbInner = styled('div', {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
})

export const Img = styled('img', {
    display: 'block',
    width: 200,
    height: 200
})

export const QuizInputGrp = styled(Col, {
    padding: '0rem 1rem',
    position: 'relative',

    '& .optRadio': {
        position: 'absolute',
        right: '1rem',
        top: 0,
        fontSize: 12
    },

    '& .ans': {
        border: '1px solid $success'
    }
})

export const VerticalButton = styled(Button, {
    '& p': {
        textOrientation: 'mixed',
        writingMode: 'vertical-lr',
        maxWidth: '1rem',
        height: '90vh'
    }
    
})

export const Que = styled("div", {  
    background: '$inputBg',
    padding: '1rem 1.5rem',
    margin: '0.4rem',
    wordWrap: 'break-word',
    borderRadius: 5,
    width: '100%',
    fontSize: 14,
    fontFamily: '$monospace',
    display: 'flex',
    position: 'relative',

    '& small': {
        fontSize: 14,
        position: 'absolute',
        top: 0,
        right: 6,
        opacity: 0.2
    }
})

export const Ans = styled("div", {
    background: '$inputBg',
    padding: '0.5rem 1.5rem',
    margin: '0.4rem',
    wordWrap: 'break-word',
    borderRadius: 5,
    fontSize: 14,
    fontFamily: '$monospace',

    variants: {
        ans: {
            true: {
                background: '$success'
            }
        }
    }
})

export const ImgPreview = styled('img', {
    borderRadius: 14
})

export const QuizList = styled(Row, {
    position: 'relative',
    background: '#2d2d2d',
    borderRadius: 10,
    padding: '8px 5px 5px',

    '& .action':{
        display: 'none',
        position: 'absolute',
        top: '-30px',
        right: '-20px',

        '& .remove':{
            '&:hover':{
                background: '$red9',
                color: '#111111'
            }
        }
    },
    '&:hover':{
        '& .action':{
            position: 'absolute',
            display: 'flex',
            top: '10px',
            right: '10px'
        }
    }
})