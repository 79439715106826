import { styled, keyframes } from "../../../stitches.config";

const gradient = keyframes({
    '0%': {
        backgroundPosition: '0% 44%'
    },
    '50%': {
        backgroundPosition: '100% 57%'
    },
    '100%': {
        backgroundPosition: '0% 44%'
    }
})

export const TopGradLine = styled("div", {
    content: ' ',
    width: '100%',
    height: '0.2rem',
    background: 'linear-gradient(223deg, #6e44ff, #b892ff, #ffc2e2, #ff90b3, #ef7a85)',
    backgroundSize: '1000% 1000%',
    animation: `${gradient} 5s ease infinite`
})

export const LoginBrand = styled("div", {
    '& .brand':{
        fontWeight: '700',
        fontSize: '1.4rem',
        lineHeight: '1rem',
        display: 'flex',
        flexDirection: 'row'
    },

    '& .brandExp':{
        fontsize: 12,
        fontWeight: '400'
    }
})

export const FormStyled = styled("form", {
    marginTop: '1.8rem'
})