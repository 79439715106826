import { styled } from "../../../stitches.config"
import { forwardRef, useState } from "react"
import { Icon } from '@iconify/react';
import Button from "./button";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { viewQuizState } from "../../states/atoms";
import { useImperativeHandle } from "react";
import InputGrp from "./input";

const ModalDiv = styled("div", {
    background: 'rgba(0,0,0,0.95)',
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    display: 'none',
    opacity: '0',
    justifyContent: 'center',
    zIndex: 99999,
    transition: 'display 2s ease-in-out',

    // '&.show': {
    //     opacity: 1,
    //     display: 'flex'
    // },

    variants: {
        vAlign: {
            startTop: {
                alignItems: 'start',
                paddingTop: '1.4rem'
            },
            startCenter: {
                alignItems: 'center'
            }
        },
        show: {
            true: {
                opacity: 1,
                display: 'flex'
            }
        }
    }
})

const ModalContentDiv = styled("div", {
    background: '$modalBg',
    border: '2px solid $gray4',
    borderRadius: 5,
    maxWidth: '100%',
    height: 'auto',
    maxHeight: '94vh',
    pointerEvents: 'auto',
    boxShadow: '0 2px 4px rgbs(0,0,0,0.2)',
    display: 'flex',
    flexDirection: 'column',

    variants: {
        width: {
            small: {
                width: '50vw'
            },
            medium: {
                width: '74vw'
            },
            large: {
                width: '90vw'
            },
            full: {
                width: '100vw'
            }
        }
    }
})

const ModalToolbarDiv = styled("div", {
    borderBottom: '2px solid $gray4',
    fontSize: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '0.6rem 0.8rem',

    
})

const ToolbarLeft = styled("div", {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
})

const ToolbarRight = styled("div", {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start'
})

const ModalBodyDiv = styled("div", {
    padding: '1rem',
    maxHeight: '94vh',
    overflowY: 'scroll'
})


const Container = ({children, title, width, show, vAlign, toolBar, closeModalBtn}, ref) => {

    console.log(`ModalStatus: ${show}`)
    if(show)
    return(
        <ModalDiv vAlign={vAlign} show={show}>
            <ModalContentDiv width={width}>
                {toolBar && 
                    <ModalToolbarDiv>
                        <ToolbarLeft>
                        {title}
                        </ToolbarLeft>
                        <ToolbarRight>
                            
                            <Button variants="themedTrans" onClick={() => closeModalBtn()}>
                                <Icon icon="ion:close" inline />
                            </Button>
                        </ToolbarRight>
                    </ModalToolbarDiv>
                }
                
                {children}
            </ModalContentDiv>
        </ModalDiv>
    )
}

const Body = forwardRef(({children, ...rest}, ref) => {
    return(
        <ModalBodyDiv {...rest} ref={ref}>
            {children}
        </ModalBodyDiv>
    )
})

const Modal = {
    Container,
    Body
}

export default Modal