import {Badge, Button, Col, Container, InputGrp, Row} from "../../components/UI"
import { FormStyled, LoginBrand, TopGradLine } from "./login.style"
import { Icon } from '@iconify/react';
import { gql, useMutation } from '@apollo/client';
import { useForm } from "react-hook-form";
import Helmet from "react-helmet";
import { useEffect } from "react";
import { dynamicNavs, userD } from "../../states/atoms";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

const loginQl = gql`
    mutation IneedAccess($Id2: String!, $Pass2: String!) {
        ineedAccess(id: $Id2, pass: $Pass2) {
            token
            user {
                id
                name
                field
                userType
                email
            }
            insti {
                id
                name
            }
        }
    }
`

const LoginPg = () => {
    const UserIDState = useSetRecoilState(userD)
    const setDynamicNav = useSetRecoilState(dynamicNavs)
    setDynamicNav(1)
    const navigate = useNavigate()
    const {register, getValues, handleSubmit} = useForm({
        mode: 'all',
        defaultValues: {
            userid: "",
            password: ""
        }
    })

    const [iam, { data, loading, error }] = useMutation(loginQl);

    const onSubmit = ({userid, password}) => {
        console.log(userid, password);
        iam({ variables: { Id2: userid, Pass2: password } });
    }

    if(data)console.log(data)
    if(loading)console.log(loading)

    useEffect(() => {
        if(data){ 
            const { token, user, insti } = data.ineedAccess
            UserIDState(user.id)
            sessionStorage.setItem('haha',token);
            sessionStorage.setItem('user',JSON.stringify(user));
            sessionStorage.setItem('insti',JSON.stringify(insti));
                navigate('/',{replace: true, state: {username: user.name}})
        }
    }, [data])
    
    return(
        <Container>
            <Helmet>
                <title>GenFac - Genosis Manager for Faculties</title>
            </Helmet>
            <Row>
                <TopGradLine />
            </Row>
            <Row css={{justifyContent: 'center', mt: '5rem'}}>
                <Col size={3}>
                    <LoginBrand>
                        <div className="brand">GenFac <Badge variants="success" edge>Stable</Badge> </div>
                        <span className="brandExp">Genosis Manager for Faculties</span>
                    </LoginBrand>
                    {
                        !loading &&
                        <FormStyled onSubmit={handleSubmit(onSubmit)}>
                            <InputGrp placeholder="User ID (FAC00xxxx)" autoComplete="username" type="text" {...register("userid", {required: true})} />
                            <InputGrp placeholder="Password" autoComplete="current-password" type="password" {...register("password", {required: true})} />
                            <Button type="submit" variants="primary" css={{margin: '0', width: '100%'}} ><span>Login</span> <Icon icon="ri:login-circle-line" width="18" inline /></Button>
                        </FormStyled>
                    }
                    {
                        loading && <div style={{marginTop: '1rem'}}><Icon icon="line-md:loading-twotone-loop" inline /> Checking your credentials ...</div>
                    }

                </Col>
            </Row>
        </Container>
    )
}


export default LoginPg