import { styled } from "../../../stitches.config"

const HrLine = styled('div', {
    content: ' ',
    border: '1px $gray5 solid',
    width: '100%',
    height: 2
})

const VrLine = styled('div', {
    content: ' ',
    border: '1px $gray5 solid',
    width: 2,
    position: 'absolute',
    right: 0,
    height: '100%'
})

const Separator = ({dir}) => {
    if(dir === 'hr') return(<HrLine />)
    return(<VrLine />)
}

export default Separator