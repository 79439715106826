import { styled } from "../../../stitches.config"
import { forwardRef } from "react"

const RadioInput = styled("input", {
    fontSize: 12,

})

const Radio = forwardRef(({name, value, children, className, onChange, ...props}, ref) => (
    
        <label className={className}>
            <RadioInput type="radio" name={name} value={value} onChange={onChange} {...props} ref={ref} /> {children}
        </label>
    
))

export default Radio