import { styled } from "../../../stitches.config"

const ButtonStyled = styled('button', {
    padding: '0.6rem 0.6rem',
    margin: '0rem 0.3rem',
    borderRadius: 10,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 'auto',
    minHeight: '2.4rem',
    minWidth: 22,
    userSelect: 'none',

    [`& .twoLine`]: {
        fontSize: 12,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        textAlign: 'center',

        [`& span:first-child`]: {
            fontWeight: 700
        },
        [`& span:nth-child(2)`]: {
            textAlign: 'center',

        }
    },
    variants:{ 
        hide: {
            true: {
                display: 'none'
            },
            false: {
                display: 'flex'
            }
        },
        colors:{
            primary: {
                background: '$primary',
                color: '$blue1',
                border: 0,
                '&:hover': {
                    background: '$blue11'
                }
            },
            secondary: {
                background: '$neutral',
                color: '$neutralColor',
                border: '0'
            },
            success: {
                background: '$success',
                color: '$whiteA12',
                border: '0'
            },
            danger: {
                background: '$red9',
                color: '#000000',
                border: '0'
            },
            warning: {
                background: '$warning',
                color: '$whiteA1',
                border: '0'
            },
            themedBorder: {
                background: 'transparent',
                border: '$gray7 solid 2px',
                color: '#fff',

                '&:hover': {
                    background: '$gray4'
                }
            },
            themed: {
                background: '$themedBtn.bg',
                border: 0,
                color: '$themedBtn.color',

                '&:hover': {
                    background: '$gray4'
                }
            },
            themedTrans: {
                background: 'transparent',
                border: 0,
                color: '#fff',

                '&:hover': {
                    background: '$gray4'
                }
            },
            themedHover:{
                background: 'transparent',
                border: 0,
                color: '$foreground',

                '&:hover': {
                    background: '$themedBtBg ',
                    color: '$themedBtColor'
                }
            }
        }  
    },
})

const Button = ({children, variants, margin, hide, ...rest}) => {
    return(
        <ButtonStyled type="button" colors={variants} hide={hide} {...rest} >
            {children}
        </ButtonStyled>
    )
}

export default Button