import { Button } from '../UI';
import { Icon } from '@iconify/react';
import { gql, useMutation } from '@apollo/client';
import { store } from '../../states/db';
import { useRecoilValue } from 'recoil';
import { UserIDSelector, UserInstiSelector } from '../../states/atoms';
import { formatISO, getUnixTime } from 'date-fns';
import {Prompt} from '../UI';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'


const UploadLectureGQL = gql`
    mutation UploadNewLecture($course: CourseDocIn, $quiz: [QuizDocIn]) {
        uploadNewLecture(course: $course, quiz: $quiz) {
            quizStatus
            courseStatus
        }
    }
`

const DeleteImg = gql`
    mutation DeleteCFimg($imgId: String) {
        DeleteImage(imgId: $imgId){
            success
        }
    }
`

const DeleteVideo = gql`
    mutation DeleteCFVid($vid: String) {
        DeleteVideo(vid: $vid) {
            success
        }
    }
`
export const PublishLecture = ({setPromptState}) => {
    const navigate = useNavigate()
    console.log('Publish Lecture Btn Clicked')
    const useridstate = useRecoilValue(UserIDSelector)
    const UserInsti = useRecoilValue(UserInstiSelector)
    console.log(UserInsti)
    const [startUpload, { data, loading, error }] = useMutation(UploadLectureGQL);

    const handleProceed = async() => {
        const lecData = await store.currentEdits.bulkGet([`${useridstate}metaFormCurr`, `${useridstate}videoCurr`, `${useridstate}editorCurr`, `${useridstate}quizCurr`, `${useridstate}coverCurr`, `${useridstate}quizMeta`])
        
        const quizArr = lecData[3].value.map(e => e.qid)
        const quizData = await store.quizCurr.bulkGet(quizArr)
        // console.log(quizData)
        const lid = `${UserInsti.insti.id}${UserInsti.user.id}le${Date.now()}`
        
        const course = prepareCourseDoc(lecData[0], lecData[1], lecData[2], lecData[3], lecData[5], lecData[4], lid)
        const quiz = prepareQuizDoc(quizData, lid, lecData[0].value.ltitle,lecData[3].value)

        console.log(course)
        console.log(quiz)
        startUpload({
            variables: {
                course,
                quiz
            }
        })
    }


    const prepareQuizDoc = (quizData, lid, ltitle, qLec) => {
        const panel = quizData.map((quizPanel, index) => {
            let tscore = 0, tt = 0
            const qname = qLec[index].qname
            
            const eachQuiz = quizPanel.value.map((eachQue, index) => {
                tscore += parseInt(eachQue.mark)
                tt += parseInt(eachQue.timer)
                return {
                    no: index+1,
                    q: eachQue.q,
                    i: eachQue.imgSrc ? {
                        provider: 'cf',
                        imgId: eachQue.imgSrc.imgId 
                    } : null,
                    c: [
                        { no: 1, text: eachQue.opt1 },
                        { no: 2, text: eachQue.opt2 },
                        { no: 3, text: eachQue.opt3 },
                        { no: 4, text: eachQue.opt4 },
                    ],
                    t: 'mcq',
                    ti: parseInt(eachQue.timer),
                    a: eachQue.ans,
                    s: parseInt(eachQue.mark)
                }
            })
            const quizMeta = {
                id: qLec[index].qid,
                lid,
                lT: ltitle,
                qT: qname,
                noq: quizPanel.value.length,
                score: tscore,
                tt,
                cdata: formatISO(Date.now(), {representation: 'date'}),
                author: {
                    id: UserInsti.user.id,
                    InstiId: UserInsti.insti.id,
                    name: UserInsti.user.name
                },
                type: 'quiz',
                quiz: eachQuiz

            }
            return quizMeta
        })
        return panel
        // console.log(panel)
    }

    const prepareCourseDoc = (meta, video, editor, quiz, quizMeta, cover, lid) => {
        
        const ltype = video.value ? 'vt' : editor.value && 'to' 
        
        const CourseDOC = {
            id: lid,
            lid,
            type: 'lecture',
            ltype,
            instiId: UserInsti.insti.id,
            ltitle: meta.value.ltitle,
            stitle: meta.value.stitle,
            subject: meta.value.subject,
            stdlevel: meta.value.stdlevel,
            tags: meta.value.TagsNarray,
            content: editor.value,
            lvurl: {
                vid: video ? video.value.vid : "null",
                provider: video ? video.value.server : "null"
            },
            status: useridstate.includes('DEMO') ? 'published' : 'drafted',
            statusE: 'Auto Approved for DemoAC',
            fac: {
                id: UserInsti.user.id,
                name: UserInsti.user.name,
                dep: UserInsti.user.field
            },
            quiz: quiz.value,
            quizMeta: quizMeta.value,
            ddate: formatISO(Date.now(),{representation: 'date'}),
            pdate: useridstate.includes('DEMO') ? formatISO(Date.now(),{representation: 'date'}) : 'nill',
            lcount: 0,
            ccount: 0,
            cover: {
                imgId: cover ? cover.value.imgId : "null" ,
                provider: cover ? cover.value.provider : "null"
            },
            isDemo: useridstate.includes('DEMO') ? true : false,
            restrict: "null"
        }
        // console.log(CourseDOC)
        return CourseDOC
    }

    const openNewLecture = () => {
        clearNonMedia(useridstate)
        window.location.reload()

    }
   
    console.log(data)

    return(
        <Prompt.Footer color="info">
        {data?.uploadNewLecture.courseStatus === 201 && data?.uploadNewLecture.quizStatus === 201 ? 
            <p><Icon icon="line-md:circle-to-confirm-circle-transition" width="24" style={{marginRight: '5px'}} /> Uploaded</p>: 
            <p>Are you sure you want to publish lecture and quizes ?</p> 
        }
        
        <div className="btnCont">
            {data?.uploadNewLecture.courseStatus === 201 && data?.uploadNewLecture.quizStatus === 201 ? 
            <>
                <Button variants="secondary" onClick={() => navigate('/')}>Go to Dashboard</Button>
                <Button variants="primary" 
                onClick={() => openNewLecture() } >
                    <span><Icon icon="akar-icons:cloud-upload" width="18" />Add Another Lecture</span> 
                </Button> 
            </> : 
            <>
                <Button variants="secondary" onClick={() => setPromptState(false)}>Cancel</Button>
                <Button variants="primary" 
                onClick={() => handleProceed() } >
                    <span><Icon icon="akar-icons:cloud-upload" width="18" />Publish</span> 
                </Button> 
            </> 
            }
            
        </div>
            
        </Prompt.Footer>
    )
}

export const SaveLocally = ({setPromptState}) => {
    const useridstate = useRecoilValue(UserIDSelector)
    const navigate = useNavigate()
    const [done, setDone] = useState(false)

    const save = () => {
        console.group('SaveLocally(): fn started')
        console.log('Save Locally fn started')
        // IndexDB Transaction
        store.transaction("rw", store.currentEdits, store.quizCurr, store.savedCourses, store.savedQuiz, async() => {
            const [meta, video, editor, quiz, cover, quizMeta] = await store.currentEdits.bulkGet([
                `${useridstate}metaFormCurr`, 
                `${useridstate}videoCurr`, 
                `${useridstate}editorCurr`, 
                `${useridstate}quizCurr`, 
                `${useridstate}coverCurr`, 
                `${useridstate}quizMeta`])

                console.log({
                    meta: meta?.value,
                    video: video ? video?.value : "", 
                    editor: editor ? editor?.value : "", 
                    quiz: quiz ? quiz?.value : [], 
                    cover: cover ? cover?.value : "", 
                    quizMeta: quizMeta ? quizMeta?.value : "", 
                    userid: useridstate})

            await store.savedCourses.add({
                meta: meta?.value,
                video: video ? video?.value : "", 
                editor: editor ? editor?.value : "", 
                quiz: quiz ? quiz?.value : [], 
                cover: cover ? cover?.value : "", 
                quizMeta: quizMeta ? quizMeta?.value : "", 
                userid: useridstate})
                .then(() =>
                {
                    console.log('%cQuiz Saved in savedCourses Table', 'font-weight: bold; background: green;')
                    // clearNonMedia()
                }
            ).catch((err) => {
                console.log(err)

            })
        
            const quizArr = quiz.value.map(e => e.qid)
            const quizes = quiz?.value ? await store.quizCurr.bulkGet(quizArr).then().catch('Error in getting Quizes') : []
            
            console.log(quizArr)

            if(quizArr.length > 0){
                await store.savedQuiz.bulkAdd(quizes).then(
                    setDone(true)
                )
            }
            console.groupEnd()
        })
        // End IndexDB Transaction
    }

    const openNewLecture = () => {
        clearNonMedia(useridstate)
        window.location.reload()

    }

    const goto = () => {
        clearNonMedia(useridstate)
        setPromptState(false)
        navigate("/")
        
    }
    

    
    return(
        <Prompt.Footer color="info">
            {done ? <p><Icon icon="line-md:circle-to-confirm-circle-transition" width="24" style={{marginRight: '5px'}} /> Saved !</p> 
            : <p>Edit Later ? Then save it in local.</p> }
            
            <div className="btnCont">
                {done ? 
                <>
                    <Button variants="secondary" onClick={() => goto()}>Go to Saved Lecture</Button>
                    <Button variants="primary"
                    onClick={() => openNewLecture()} >
                        <span><Icon icon="raphael:db" width="18" />Create New Lecture</span>
                    </Button> 
                </> : <>
                    <Button variants="secondary" onClick={() => setPromptState(false)}>Cancel</Button>
                    <Button variants="primary"
                    onClick={() => save()} >
                        <span><Icon icon="raphael:db" width="18" />Save Locally !</span>
                    </Button> 
                </>
                }
                
            </div>
        </Prompt.Footer>
    )
}

export const clearNonMedia = async(useridstate) => {
    const quizArray = await store.currentEdits.get(`${useridstate}quizCurr`)
    const quizArr = quizArray.value.map(e => e.qid)
    await store.quizCurr.bulkDelete(quizArr)
    await store.currentEdits.bulkDelete([`${useridstate}metaFormCurr`, `${useridstate}videoCurr`, `${useridstate}editorCurr`, `${useridstate}quizCurr`, `${useridstate}coverCurr`])
}

// export const clearCurr = () => {
//     store.transaction("rw", store.currentEdits, store.quizCurr, async() => {
//         const ceArray = await store.currentEdits.get(`${useridstate}quizCurr`)
//         const quizArr = ceArray?.value.map(e => e.qid) || false
//         quizArr && await store.quizCurr.bulkDelete(quizArr),
//         await store.currentEdits.bulkDelete([`${useridstate}metaFormCurr`, `${useridstate}videoCurr`, `${useridstate}editorCurr`, `${useridstate}quizCurr`, `${useridstate}coverCurr`]) 
//     })
    
// }

export const ClearAll = ({setPromptState}) => {
    const useridstate = useRecoilValue(UserIDSelector)
    const[alertState, setAlertState] = useState(false)
    const refreshPage = useNavigate()
    const [deleteImg, { data: imgdelete, loading: imgLoad, error: imgError}] = useMutation(DeleteImg);
    const [deleteVid, { data: viddelete, loading: vidLoad, error: vidError}] = useMutation(DeleteVideo);

    const startClearMedia = () => {
        store.transaction("rw",store.currentEdits, store.quizCurr, async() => {
            const ceArray = await store.currentEdits.bulkGet([`${useridstate}quizCurr`, `${useridstate}videoCurr`, `${useridstate}coverCurr`])
            const quizArr = ceArray[0]?.value.map(e => e.qid) || false
            const imgId = ceArray[2]?.value.imgId || false
            const vid = ceArray[1]?.value.vid || false
    
            console.log(quizArr)
            console.log(imgId)
            console.log(vid)

            imgId && await deleteImg({variables: {imgId}})
            vid && await deleteVid({variables: {vid}})
            if(await imgdelete.success && await viddelete.success){ 
                return await Promise.all([
                    quizArr && await store.quizCurr.bulkDelete(quizArr),
                    await store.currentEdits.bulkDelete([`${useridstate}metaFormCurr`, `${useridstate}videoCurr`, `${useridstate}editorCurr`, `${useridstate}quizCurr`, `${useridstate}coverCurr`]) 
                ])
            }
            

        }).then(() => {
            setAlertState(true)
        }).catch((err) => {
            console.log(err)
        })
        
        
            
        
        
        
        
    }

    const refresh = () => {
        refreshPage("/addlecture", { replace: true })
        window.location.reload()
    }

    return(
        <Prompt.Footer color={alertState ? "info" : "danger"}>
            {alertState ? 
            <>
                <p><Icon icon="line-md:circle-to-confirm-circle-transition" /> Successfully Cleared</p>
                <div className="btnCont">
                    <Button variants="primary" onClick={() => refresh() }>Refresh Page</Button>
                </div>
                
            </>
            :
            <>
                <p>Are you sure you want to clear all fields and uploads ?</p> 
                <div className="btnCont">
                    <Button variants="secondary" onClick={() => setPromptState(false)}>Cancel</Button>
                    <Button variants="danger" 
                    onClick={() => startClearMedia() } >
                        <span><Icon icon="typcn:delete" width="18" />Clear</span> 
                    </Button> 
                </div>
            </>
            }
            
            
        </Prompt.Footer>
    )
}

export const DeleteLecture = ({setPromptState}) => {
    const useridstate = useRecoilValue(UserIDSelector)
    const[alertState, setAlertState] = useState(false)
    const refreshPage = useNavigate()
    const matches = useParams()
    const [deleteImg, { data: imgdelete, loading: imgLoad, error: imgError}] = useMutation(DeleteImg);
    const [deleteVid, { data: viddelete, loading: vidLoad, error: vidError}] = useMutation(DeleteVideo);

    const startClearMedia = () => {
        store.transaction("rw",store.savedCourses, store.savedQuiz, async() => {
            const ceArray = await store.savedCourses.get(parseInt(matches.id))
            const quizArr = ceArray.quiz.map(e => e.qid) || false
            const imgId = ceArray.cover.imgId || false
            const vid = ceArray.video.vid || false
    
            console.log(quizArr)
            console.log(imgId)
            console.log(vid)

            imgId && await deleteImg({variables: {imgId}})
            vid && await deleteVid({variables: {vid}})
            if(await imgdelete.success && await viddelete.success){ 
                return await Promise.all([
                    quizArr && await store.savedQuiz.bulkDelete(quizArr),
                    await store.savedCourses.delete(parseInt(matches.id)) 
                ])
            }
            

        }).then(() => {
            setAlertState(true)
        }).catch((err) => {
            console.log(err)
        })
        
    }

    const refresh = () => {
        refreshPage("/addlecture", { replace: true })
        window.location.reload()
    }

    return(
        <Prompt.Footer color={alertState ? "info" : "danger"}>
            {alertState ? 
            <>
                <p><Icon icon="line-md:circle-to-confirm-circle-transition" /> Successfully Cleared</p>
                <div className="btnCont">
                    <Button variants="primary" onClick={() => refresh() }>Refresh Page</Button>
                </div>
                
            </>
            :
            <>
                <p>Are you sure you want to clear all fields and uploads ?</p> 
                <div className="btnCont">
                    <Button variants="secondary" onClick={() => setPromptState(false)}>Cancel</Button>
                    <Button variants="danger" 
                    onClick={() => startClearMedia() } >
                        <span><Icon icon="typcn:delete" width="18" />Clear</span> 
                    </Button> 
                </div>
            </>
            }
            
            
        </Prompt.Footer>
    )
}

export const PublishSaved = ({setPromptState}) => {
    const useridstate = useRecoilValue(UserIDSelector)
    const UserInsti = useRecoilValue(UserInstiSelector)
    const[alertState, setAlertState] = useState(false)
    const [startUpload, { data, loading, error }] = useMutation(UploadLectureGQL);
    const matches = useParams()


    const handleProceed = async() => {
        const lecData = await store.savedCourses.get(parseInt(matches.id))
        // console.log(lecData)
        
        const quizArr = lecData.quiz.map(e => e.qid)
        const quizData = await store.savedQuiz.bulkGet(quizArr)
        // console.log(quizData)
        const lid = `${UserInsti.insti.id}${UserInsti.user.id}le${Date.now()}`
        
        const course = prepareCourseDoc(lecData, lid)
        const quiz = prepareQuizDoc(quizData, lid, lecData.meta.ltitle,lecData.quiz)

        console.log(course)
        console.log(quiz)
        // startUpload({
        //     variables: {
        //         course,
        //         quiz
        //     }
        // })
    }

    const prepareCourseDoc = (SavedLec, lid) => {
        const {meta, video, editor, quiz, quizMeta, cover} = SavedLec
        const ltype = video ? 'vt' : editor && 'to' 

        const CourseDOC = {
            id: lid,
            lid,
            type: 'lecture',
            ltype,
            instiId: UserInsti.insti.id,
            ltitle: meta.ltitle,
            stitle: meta.stitle,
            subject: meta.subject,
            stdlevel: meta.stdlevel,
            tags: meta.tags,
            content: editor,
            lvurl: {
                vid: video ? video.vid : "null",
                provider: video ? video.server : "null"
            },
            status: useridstate.includes('DEMO') ? 'published' : 'drafted',
            statusE: 'Auto Approved for DemoAC',
            fac: {
                id: UserInsti.user.id,
                name: UserInsti.user.name,
                dep: UserInsti.user.field
            },
            quiz,
            quizMeta,
            ddate: formatISO(Date.now(),{representation: 'date'}),
            pdate: useridstate.includes('DEMO') ? formatISO(Date.now(),{representation: 'date'}) : 'nill',
            lcount: 0,
            ccount: 0,
            cover,
            isDemo: useridstate.includes('DEMO') ? true : false,
            restrict: "null"
        }

        return CourseDOC
    }

    const prepareQuizDoc = (quizData, lid, ltitle, qLec) => {
        const panel = quizData.map((quizPanel, index) => {
            let tscore = 0, tt = 0
            const qname = qLec[index].qname
            
            const eachQuiz = quizPanel.value.map((eachQue, index) => {
                tscore += parseInt(eachQue.mark)
                tt += parseInt(eachQue.timer)
                return {
                    no: index+1,
                    q: eachQue.q,
                    i: eachQue.imgSrc ? {
                        provider: 'cf',
                        imgId: eachQue.imgSrc.imgId 
                    } : null,
                    c: [
                        { no: 1, text: eachQue.opt1 },
                        { no: 2, text: eachQue.opt2 },
                        { no: 3, text: eachQue.opt3 },
                        { no: 4, text: eachQue.opt4 },
                    ],
                    t: 'mcq',
                    ti: parseInt(eachQue.timer),
                    a: eachQue.ans,
                    s: parseInt(eachQue.mark)
                }
            })
            const quizMeta = {
                id: qLec[index].qid,
                lid,
                lT: ltitle,
                qT: qname,
                noq: quizPanel.value.length,
                score: tscore,
                tt,
                cdata: formatISO(Date.now(), {representation: 'date'}),
                author: {
                    id: UserInsti.user.id,
                    InstiId: UserInsti.insti.id,
                    name: UserInsti.user.name
                },
                type: 'quiz',
                quiz: eachQuiz

            }
            return quizMeta
        })
        return panel 
    }

    return(
        <Prompt.Footer color="info">
            {data?.uploadNewLecture.courseStatus === 201 && data?.uploadNewLecture.quizStatus === 201 ? 
                <p><Icon icon="line-md:circle-to-confirm-circle-transition" width="24" style={{marginRight: '5px'}} /> Uploaded</p>: 
                <p>Are you sure you want to publish lecture and quizes ?</p> 
            }
        
            <div className="btnCont">
                {data?.uploadNewLecture.courseStatus === 201 && data?.uploadNewLecture.quizStatus === 201 ? 
                <>
                    <Button variants="secondary" onClick={() => navigate('/')}>Go to Dashboard</Button>
                    <Button variants="primary" 
                    onClick={() => openNewLecture() } >
                        <span><Icon icon="akar-icons:cloud-upload" width="18" />Add Another Lecture</span> 
                    </Button> 
                </> : 
                <>
                    <Button variants="secondary" onClick={() => setPromptState(false)}>Cancel</Button>
                    <Button variants="primary" 
                    onClick={() => handleProceed() } >
                        <span><Icon icon="akar-icons:cloud-upload" width="18" />Publish</span> 
                    </Button> 
                </> 
                }
                
            </div>
            
        </Prompt.Footer>
    )
}