import { styled } from "../../../stitches.config";

const LabelStyled = styled("label", {
    fontSize: 12,
    fontWeight: 400
})

const Label = ({children, ...props}) => {
    <LabelStyled >
        {children}
    </LabelStyled>
}

export default Label