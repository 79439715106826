import { styled } from "../../../../stitches.config"
import { Card, Col } from "../../../components/UI"

export const PrevCard = styled("div", {
    width: 260,
    borderRadius: 15,
    background: '$cardBg'
})

export const ImgDetails = styled(Col, {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '0.4rem',
    marginBottom: '0.4rem',

    '& span': {
        fontSize: '0.8rem'
    },
}) 