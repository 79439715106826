import { Col, Info, InputGrp, Select, Row, Container, Badge } from "../../../components/UI"
import { useForm, useFormState } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from "react";
import { store } from "../../../states/db";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { useLiveQuery } from "dexie-react-hooks";
import {UserIDSelector, newCGID, viewCState, viewCourseID, CurrentNotification} from "../../../states/atoms"
import { NonInp } from "./metaForm.style";
import subjInfo from '../subject.json'

const schema = yup.object({
    meta: yup.object({
        ltitle: yup.string().min(10).required(),
        subject: yup.string().required(),
        stitle: yup.string().required(),
        stdlevel: yup.string().required()
        // tags: yup.array(yup.string().required()).required()
    })
    
}).required();

const MetaForm = ({savedCourse, cid, defaultOptionValue}) => {
    const {name: uname, id: uid} = JSON.parse(sessionStorage.getItem('user'))
    const {id: iid, name: iname} = JSON.parse(sessionStorage.getItem('insti'))
    // getting current session user's ID
    const cgid = useRecoilValue(newCGID)
    const useridstate = useRecoilValue(UserIDSelector)
    const [show, setShow] = useRecoilState(viewCState)
    const viewCID = useRecoilValue(viewCourseID)
    
    const [cvalue, setCValue] = useState();
    const [defaultValue, setDefaultValue] = useState();
    const setCNotify = useSetRecoilState(CurrentNotification)

    const {register, getValues, setValue, control } = useForm({
        mode: 'all',
        defaultValues: {
            ltitle: '',
            stitle: '',
            tags: ''
        },
        resolver: yupResolver(schema)
    })

    const { dirtyFields, errors, isDirty } = useFormState({
        control
    });


    const tags = cid ? useLiveQuery(() => store.savedCourses.get(cid), [cid]) :
    useLiveQuery(() => store.currentEdits.get({type: 'metaFormCurr'}), [])

    
    useEffect(()=> {
        if(savedCourse){
            const {ltitle, subject, stitle, stdlevel, TagsNarray} = savedCourse
            setValue('meta',{ltitle, subject, stitle, stdlevel, tags: TagsNarray?.toString()})
        }
        
    }, [savedCourse])

    const getMetaValue = async(data) => {
        // console.log(savedCourse, cid)
        const {ltitle, subject, stitle, stdlevel, tags} = getValues('meta')
        const TagsNarray = tags && tags.length > 0 && tags.split(',').map(item => item.trim())
        // console.log(ltitle, subject, stitle, tags)
        if(savedCourse && cid){
            console.log("saving locally: test")
            store.savedCourses.where('id').equals(parseInt(cid)).modify(x => Object.assign(x.meta,{ltitle, subject, stitle, stdlevel, TagsNarray})).then().catch(() => console.error('Error on saving it in local'))
        } else {
            store.currentEdits.put({type: `${useridstate}metaFormCurr`, value:{ltitle, subject, stitle, stdlevel, TagsNarray}})
        }
    }

    return(
    <Container>
        <Row>
            <Info variant="primaryTrans" size="50" icon="fluent:info-28-filled">
                <ul>
                    <li>All the fields are mandatory</li>
                    <li>Tags are "comma" separated</li>
                    <li>To add new Subject, Please Raise request to admin!</li>
                </ul>
            </Info>
        </Row>
        <Row>
        <Col size={6}>
            <form>
                <InputGrp type="text" label="Lecture Title"  name="ltitle" autoComplete="off" {...register("meta.ltitle")} onBlur={getMetaValue}
                errors={errors.meta?.ltitle ? errors.meta?.ltitle.message : 'false'}  />

                <Select label="Select Subject"  {...register("meta.subject")} onBlur={getMetaValue}
                errors={errors.meta?.subject ? errors.meta?.subject.message : 'false'}>  
                    {subjInfo.subjects.map((item, index) => {
                        return(
                            <option key={item.abbr+index} value={item.name}>{item.name}</option>
                        )
                    })} 
                    {/* <option value="physics">Physics</option>
                    <option value="maths">Mathemetics</option>
                    <option value="science">Science</option>
                    <option value="social">Social</option>
                    <option value="chemistry">Chemistry</option>
                    <option value="computer">Computer Science</option>
                    <option value="commerce">Commerce</option>
                    <option value="accounts">Accounts</option>
                    <option value="tamil">Tamil</option>
                    <option value="english">English</option> */}
                </Select>

                <InputGrp type="text" label="Secondary Title"   name="stitle" autoComplete="off"  {...register("meta.stitle")} onBlur={getMetaValue} 
                errors={errors.meta?.stitle ? errors.meta?.stitle.message : 'false'} />
                <Select label="Suitable for"  {...register("meta.stdlevel")} onBlur={getMetaValue}
                errors={errors.meta?.stdlevel ? errors.meta?.subject.stdlevel : 'false'}>   
                    {subjInfo.stream.map((item, index) => {
                        return(
                            <option key={item.abbr+index} value={item.abbr}>{`${item.name} (${item.grade})`}</option>
                        )
                    })} 
                </Select>

                <InputGrp type="text" label="Tags"   name="tags" autoComplete="off"  {...register("meta.tags")} onBlur={getMetaValue} 
                errors={errors.meta?.tags ? errors.meta?.tags.message : 'false'} />
            </form>
        </Col>
        <Col size={6}>
            <NonInp>
                <span className="title">Author</span>
                <p className="det">{uname}<Badge variants="themedTrans">{uid}</Badge></p>
            </NonInp>
            <NonInp css={{marginTop: '1rem'}}>
                <span className="title">Course For</span>
                <p className="det">{iname}</p>
            </NonInp>
        </Col>
    </Row>
    </Container>
    )
}



export default MetaForm