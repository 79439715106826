import { styled } from "../../../stitches.config"
import { Icon } from '@iconify/react';

const InfoDiv = styled("div", {
    fontSize: '0.8rem',
    fontFamily: '$monospace',
    borderRadius: 5,
    padding: '1rem',
    marginBottom: '1rem',
    position: 'relative',
    overflow: 'hidden',
    
    '& ul': {
        marginLeft: '1rem'
    },

    '& .iconify': {
        opacity: '0.2',
        position: 'absolute',
        right: '-1rem',
        top: '-1rem'
    },

    variants: {
        variant: {
            primaryTrans: {
                background: '$btnTransBg',
                color: '$btnTransColor'
            }
        },
        size: {
            100: {
                width: '100%'
            },
            75: {
                width: '75%'
            },
            50: {
                width: '50%'
            },
            25: {
                width: '25%'
            }
        }
    }
})

const Info = ({children, variant, size, icon}) => {
    return(
        <InfoDiv variant={variant} size={size}>
            {children}
            <Icon icon={icon} width="72px" />
        </InfoDiv>
    )
}

export default Info