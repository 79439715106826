import { styled } from "../../../stitches.config"

export const PageContainer = styled('div',{
    width: '100%',
    padding: '1rem',
    height: '100%',
    overflowY: 'scroll'
})

export const SectionTitle = styled('h2', {
    fontSize: '1rem',
    fontWeight: 700,
    letterSpacing: 1,
    color: '$secTitle',
    marginBottom: '1.2rem'
})

export const EditorContainer = styled('div', {
    background: '$ejsBg',
    border: '1px dashed $ejsBorder',
    color: '$ejsColor',
    borderRadius: 5,
    height: '100%',
    minHeight: 100,
    width: '60vw',
    paddingTop: '1rem',

    '& .ce-block':{
        '&.ce-block--selected':{
            '& .ce-block__content':{
                background: '#2d2d2d'
            }
        }
    },

    '& .ce-inline-toolbar': {
        background: '#212121',
        color: '$color',
        border: '1px #2d2d2d solid',

        '& .ce-inline-toolbar__dropdown:hover':{
            background: '#2d2d2d'
        },

        '& .ce-inline-toolbar__buttons':{
            '& > *:hover':{
                background: '#2d2d2d'
            }
            
        },

        '& .ce-conversion-toolbar':{
            background: '#212121',
            border: '1px #2d2d2d solid',

            '& .ce-conversion-tool:hover':{
                background: '#2d2d2d'
            },

            '& .ce-conversion-tool__icon': {
                background: '#2d2d2d'
            }
        }
    },

    '& .ce-inline-tool': {
        color: '$color'
    },

    '& .ce-convertion-toolbar': {
        background: '$background',
        color: '$color'
    },

    '& .ce-toolbar':{
        '& .ce-toolbar__actions': {
            '& .ce-toolbar__plus': {
                background: '$btnTransBg',
                color: '$btnTransColor'
            }
        },

        
        '& .ce-popover': {
            background: '#1d1d1d',
            // background: '$ejsPopover',
            color: '$ejsPopoverColor',
            border: '2px solid #2d2d2d',

            '& .cdx-search-field': {
                background: '$inputBg'
            },

            '& .ce-popover__items':{
                '& .ce-popover__item': {
                    '&:hover':{
                        background: '#2d2d2d',
                        color: '$btnTransColor'
                    },
    
                    '& .ce-popover__item-icon': {
                        color: '$btnTransColor',
                        background: '$btnTransBg'
                    },
    
                    '&.ce-popover__item--focused': {
                        background: '$btnTransBg !important',
                        color: '$btnTransColor'
                    }
                }
            }
        }
            
    },

    '& .ce-toolbar > .ce-toolbox': {
        background: '$background'
    },

    
})

export const LoadingContainer = styled("div", {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.2rem',
    height: '100%',

    '& .iconify': {
        marginRight: '0.8rem'
    }
})

export const PageTitle = styled("p", {
    opacity: 0.3,
    fontWeight: 700,
    fontSize: '1.2rem',
    userSelect: 'none'
})