import { atom, selector } from "recoil"

export const NavSizeState = atom({
    key: 'NavSizeKey',
    default: 1
})

export const dynamicNavs = atom({
  key: 'navstatedynamic',
  default: 1
})

export const usert = atom({
  key: 'userkey',
  default: false
})

export const userD = atom({
  key: 'userDkey',
  default: false
})

export const UserIDSelector = selector({
  key: 'useridselector',
  get: () => {
    const user = sessionStorage.getItem('user')
    const {id} = JSON.parse(user)
    return id
  }
})

export const UserInstiSelector = selector({
  key: 'userInstiSelect',
  get: () => {
    const user = sessionStorage.getItem('user')
    const insti = sessionStorage.getItem('insti')
    return {user: JSON.parse(user), insti: JSON.parse(insti)}
  }
})

export const newCGID = atom({
  key: 'cgidkey',
  default: null
})

export const instiD = atom({
  key: 'instiDkey',
  default: false
})

export const sidenavState = atom({
  key: 'sidenavSize', 
  default: false, 
});

export const viewQuizState = atom({
  key: 'viewQState',
  default: "false"
})

export const viewQuizId = atom({
  key: 'viewQId',
  default: null
})

export const viewCState = atom({
  key: 'viewCState',
  default: false
})

export const viewCourseID = atom({
  key: 'viewCourseID',
  default: null
})


export const CurrentNotification = atom({
  key: 'currNotify',
  default: {title: 'Everything works good!', Exp: 'Have a great day', icon:'bi:bell-fill'}
})