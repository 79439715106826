import { forwardRef } from "react"
import { styled } from "../../../stitches.config"
import { DefaultValue } from "recoil"
import { ErrorInput } from "./utils"

const InputStyled = styled("input", {
    background: '$inputBg',
    color: '$color',
    maxWidth: '30vw',
    width: '100%',
    borderRadius: '5px',
    height: '3rem',
    fontSize: '1rem',
    border: 0,
    padding: '0rem 1rem',
    marginTop: '0.6rem',

    '&:focus': {
        border: 0,
        outline: 'none'
    }
})

const InputGrpDiv = styled("div", {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "flex-start",
    marginBottom: '1rem',

    '& label': {
        fontSize: '0.9rem',
        letterSpacing: '0.6px',
        display: 'flex',
        flexDirection: 'column'
    }
})


const InputGrp = forwardRef(({onChange, name, onBlur, label, errors, isDirty, css, ...props}, ref) => (
    
        <InputGrpDiv>
            <label>
                <div>{label}</div>
                <InputStyled name={name} onChange={onChange} onBlur={onBlur} ref={ref} css={css} {...props} />
            </label>
            {errors != 'false' && <ErrorInput>{errors}</ErrorInput>}
        </InputGrpDiv>
    ))

export default InputGrp