import createRandomHex from "./randomHex"
import {ThumbCont, PrevCardMeta, CardHorizontal} from './lecCard.style'
import { Card } from "../UI"
import { useId } from "react"

const CardHori = ({ltitle, stitle, subject, dur, cover, files}) => {
    console.log(files)
    const gradId = useId();
    const thumbs = cover?.imgId === 'null' ? 
    files?.length > 0 && files.map(file => <Card.Img key={file.name} src={file.preview} position="top" css={{objectFit: 'cover', borderRadius: 15, height: '150px', width: '160px'}} alt="cover-img" />) :
        <Card.Img src={`https://playwithbot.com/cdn-cgi/imagedelivery/Tj8oorGJn-kb8MPZdUcX7A/${cover?.imgId}/thumb400`} alt="coverImgPreview" position="top" css={{objectFit: 'cover', borderRadius: 15, height: '150px', width: '160px'}} />
    return(
        <CardHorizontal>
            <ThumbCont css={{padding: 5}}>
                {cover?.imgId === 'null' || files?.length > 0 ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="160px" >
                        <linearGradient id={gradId}>
                            <stop offset="5%" stopColor={`#${createRandomHex()}`} />
                            <stop offset="95%" stopColor={`#${createRandomHex()}`} />
                        </linearGradient>
                        <rect x="0" y="0" rx="15" width="160px" height="150px" fill={`url(#${gradId})`} />
                        
                    </svg> : thumbs
                }
                <p className="sub">{subject}</p>
                <p className="dur">{dur}</p>
            </ThumbCont>
            <Card.Body>
                <PrevCardMeta>
                    <p>{ltitle}</p>
                    <small>{stitle}</small>
                </PrevCardMeta>
                
            </Card.Body>
        </CardHorizontal>
    )
}

export default CardHori