import { useEffect, useId, useState, useRef, useCallback } from "react"
import { Container, Row, Col, Info } from "../../components/UI"
import EditorJS from "@editorjs/editorjs"
import Header from '@editorjs/header'
import Paragraph from '@editorjs/paragraph'
import Table from '@editorjs/table'
import Quote from '@editorjs/quote'
import ImageTool from '@editorjs/image'
import CodeTool from '@editorjs/code'
import Checklist from '@editorjs/checklist'
import LinkTool from '@editorjs/link'
import { useRecoilValue } from "recoil"
import { EditorContainer } from "./addcoursepg.style"
import imageUpload from "../../components/upload/imgUpl"
import { store } from "../../states/db"
import { UserIDSelector } from "../../states/atoms"

const TheoryEditor = ({savedCourse, cid}) => {
    const ejInstance = useRef();
    const id = useId();
    const useridstate = useRecoilValue(UserIDSelector)
    const [edjsReady, setEdjsReady] = useState(false)

    
   console.log(`Editor Report: ${savedCourse}` )

    const initEditor = useCallback(() => {
        if(id && !edjsReady){
            const edj = new EditorJS({ 
                holder: id,
                logLevel: "ERROR",
                placeholder: "Hey, Write here!!",
                data: savedCourse,
                onReady: () => {
                    console.log("EditorJS: Hey Dude")
                    ejInstance.current = edj
                    setEdjsReady(true)
                },
                onChange: (api, event) => {
                    console.log('Now I know that Editor\'s content changed!', event)
                    const content = edj.save().then((data) => {
                        saveItinLocal(data)
                    });
                },
                tools: { 
                    header: Header, 
                    paragraph: {
                        class: Paragraph,
                        inlineToolbar: true,
                    },
                    table: {
                        class: Table,
                        inlineToolbar: true,
                        config: {
                        rows: 2,
                        cols: 3,
                        },
                    },
                    quote: {
                        class: Quote,
                        inlineToolbar: true,
                        shortcut: 'CMD+SHIFT+O',
                        config: {
                        quotePlaceholder: 'Enter a quote',
                        captionPlaceholder: 'Quote\'s author',
                        },
                    },
                    image: {
                    class: ImageTool,
                    config: {
                        // endpoints: {
                        //   byFile: 'https://getuplurl.playwithbot.com/getotuimg', // Your backend file uploader endpoint
                        // }
                        uploader:{
                            async uploadByFile(file){
                                console.table(file)
                                const formData = new FormData();
                                formData.append('file', file);
            
                                const URLdata = await imageUpload()
                                const res = await fetch(URLdata, {
                                    method: 'post',
                                    body: formData,
                                })
            
                                const url = await res.json()
            
            
                                
            
                                return {
                                    success: 1,
                                    file: {
                                        url: await url.result.variants[0],
                                        // any other image data you want to store, such as width, height, color, extension, etc
                                    }
                                }
                            }
                        }
                    }
                    },
                    code: CodeTool,
                    checklist: {
                        class: Checklist,
                        inlineToolbar: true,
                    },
                    linkTool: {
                        class: LinkTool,
                        config: {
                            endpoint: '', // Your backend endpoint for url data fetching,
                        }
                    }
                }, 
                minHeight: 50,
            })
        } 
    }, [id, edjsReady])

    useEffect(() => {
        if (!ejInstance.current) {
          initEditor();
        }
        
        return() => {
            if (ejInstance.current && ejInstance.current.destroy) {
                // ejInstance.current.destroy();
                // ejInstance.current = null;
            }
        }
      },[initEditor]);

    const saveItinLocal = (data) => {
        // console.log('EDitor='+ data)
        if(savedCourse && cid){
            console.log("yup working")
            store.savedCourses.where('id').equals(parseInt(cid)).modify(x => Object.assign(x.editor, data)).then()
            .catch((err) => console.log(err && `Error: ${err}`))
        } else{
            store.currentEdits.put({type: `${useridstate}editorCurr`, value: data })
            .then()
            .catch((err) => console.log(err && `Error: ${err}`))
        }
            
        
    }


    // if(id && edjsReady)
    return(
        <Container>
            <Row>
                <Info variant="primaryTrans" size="75" icon="fluent:document-text-24-filled">
                    <ul>
                        <li>Use Tab key to open inline toolbar (Heading, Text, Image ..)</li>
                        <li>Double click and select the text to convert the type or change style</li>
                        <li>Click Dotted Icon menu to align the block or remove it.</li>
                        <li>Don't include any social media links for reference.</li>
                    </ul>
                </Info>
            </Row>
            <Row>
                <Col><EditorContainer id={id} /></Col>
            </Row>
        </Container>
        
    )
}

export default TheoryEditor