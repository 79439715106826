import { useCallback, useRef, useEffect, useState } from "react"
import { Container, Row, Col, Button, Badge, ProgressButton, Info } from "../../../components/UI"
import { StyledDropZone, VideoContainer, VideoDetails } from "./videoUpl.style"
import { Hls, Media } from '@vidstack/player-react';
import Hlsjs from 'hls.js';
import { Icon } from "@iconify/react"
import {useDropzone} from 'react-dropzone'
import * as tus from "tus-js-client";
import { Base64 } from 'js-base64';
import prettyBytes from 'pretty-bytes'
import { useRecoilValue } from "recoil"
import { store } from "../../../states/db"
import { UserIDSelector } from "../../../states/atoms"


const VideoUplSection = ({savedCourse}) => {
  // refs and states init
  const useridstate = useRecoilValue(UserIDSelector)
  const videoTagRef = useRef()
  const videoSrcRef = useRef()
  const upload = useRef()
  const {id: facid} = JSON.parse(sessionStorage.getItem('user'))
  const [videoFile, setVideoFile] = useState(savedCourse?.lfile ? savedCourse.lfile : null)
  const [mediaId, setMediaId] = useState(savedCourse?.vid || null)
  const [uplProgress, setUplProgress] = useState([null, null, 0]) //bytesUploaded, bytesTotal, Percentage of upload
  const [isUploaded, setIsUploaded] = useState(savedCourse?.vid ? 3 : 0) //0=notStated,1=started,2=paused,3=completed
  const [fileName, setFileName] = useState(null)

  console.log(videoFile)
  useEffect(()=>{
    if(savedCourse){
      setMediaId(savedCourse?.vid)
      setUplProgress([savedCourse?.bupl, savedCourse?.btotal, savedCourse?.per])
    }
  },[savedCourse])

  const updateFileDetails = (fdata) => {
      videoSrcRef.current.src = URL.createObjectURL(fdata)
      videoTagRef.current.load()

      videoTagRef.current.addEventListener('loadedmetadata', () => {
        setVideoFile({
          fname: fdata.name, 
          ftype: fdata.type, 
          fsize: fdata.size, 
          vw: videoTagRef.current.videoWidth, 
          vh: videoTagRef.current.videoHeight,
          vl: videoTagRef.current.duration,
          file: fdata
        })
      })

      console.log('videoLength:'+videoFile?.vl)
    }

    const setUploadProps = async() => {
      const val = await store.currentEdits.get(`${facid}metaFormCurr`)
      console.log(val)
      const {ltitle} = await val.value
      const fnameOnUpl = await ltitle?.split(' ').join('_')
      setFileName(fnameOnUpl)

      console.group('Upload Module');
      const sec = Math.round(videoFile?.vl) + 1     
      const maxDur = Base64.btoa(sec)
      const fname = Base64.btoa(await fnameOnUpl)
      const origins = Base64.btoa(['*.playwithbot.com', 'localhost:8787'])

      upload.current = new tus.Upload(videoFile.file, {
          endpoint: 'https://getuplurl.playwithbot.com/video/GetUplURL/v1',
          retryDelays: [0, 3000, 5000, 10000, 20000],
          chunkSize: 50 * 1024 * 1024, // Required a minimum chunk size of 5MB, here we use 50MB.
          parallelUploads: 1,
          metadata: {
            filetype: "video/mp4",
            defaulttimestamppct: 0.5
          },
          headers:{
              'Upload-Metadata': `requireSignedURLs, maxDurationSeconds ${maxDur}, name ${fname}, allowedorigins ${origins}`
          },
          uploadSize: videoFile?.fsize,
          onError: (error) => {
              console.log(`Failed because: ${error}`)
          },
          onProgress: (bytesUploaded, bytesTotal) => {
              const percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
              // console.log(bytesUploaded, bytesTotal, `${percentage  }%`)
              // setUplStatus(`${bytesToSize(bytesUploaded)} of ${bytesToSize(bytesTotal)} Uploaded`)
              setUplProgress([bytesUploaded, bytesTotal, percentage])
              if(percentage === 100.00){
                store.currentEdits.where('type').equals(`${useridstate}videoCurr`).modify(x => x.value.push([bytesUploaded, bytesTotal, percentage]))
              }
          },
          onSuccess: () => {
              console.log("%cVideo Uploaded", 'background:green; color: white; font-weight: bold; padding: 5px 5px; border-radius: 5px;')
              console.groupEnd('Upload Module');
              setIsUploaded(3)
          },
          onAfterResponse: (req, res) => {
                const value = res.getHeader("stream-media-id")
                console.log("%cMedia ID:%s", 
                'background:skyblue; color: black; padding: 5px 5px; border-radius: 5px; font-weight: bold;', 
                value)
                store.currentEdits.put({type: `${useridstate}videoCurr`, value: {server: 'cf', vid: value, lfile: {fname: videoFile.fname, ftype: videoFile.ftype, fsize: videoFile.fsize, vl: videoFile.vl, vh: videoFile.vh, vw: videoFile.vw}}})
                setMediaId(value)
            }
      })

      return "init"
  }

  const startOrResumeUpload = async() => {
      // Check if there are any previous uploads to continue.
      upload.current.findPreviousUploads().then((previousUploads) => {
          // Found previous uploads so we select the first one. 

          if (previousUploads.length) {
              upload.current.resumeFromPreviousUpload(previousUploads[0])

          }
          upload.current.start()
          setIsUploaded(1)
          // Start the upload
      })

  }

  const pauseUpload = () => {
    upload.current.abort()
    setIsUploaded(2)
  }

  const PreUploadFn = async() => {
      

      const res = await setUploadProps()
      if(res) startOrResumeUpload()
      

  }


  // React DropZone configs
  const onDrop = useCallback(acceptedFiles => {
    updateFileDetails(acceptedFiles[0])
  }, [])

  const {getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject, acceptedFiles} = useDropzone(
    {
      onDrop, 
      maxFiles: 1,
      accept: {
        'video/mp4': ['.mp4']
      },
      preventDropOnDocument: true
    }
  )

  console.log(isDragAccept)

  return(
    <Container>
        <Row>
          <Info variant="primaryTrans" size="75" icon="fluent:video-clip-20-filled">
              <ul>
                  <li>Recommeded Format: .mp4 container, AAC Audio, H264 Video, 30fps</li>
                  <li>Recommeded Resolution: 1080p with 8Mbps Bitrate or 720p with 4.8Mbps Bitrate</li>
                  <li>Only one video file is allowed for a lecture.</li>
                  <li>Video file blob cannot be restored from offline storage. needs to add file again for resume upload.</li>
              </ul>
          </Info>
        </Row>
        <Row style={{marginTop: '1rem'}}>
          <Col size={6}>
            <VideoContainer ref={videoTagRef} controls>
              <source ref={videoSrcRef} src="" type="video/mp4" />
            </VideoContainer>
          </Col>
          <Col size={6}>

              {!videoFile?.vid && 
              <Row>
                <Col size={11} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <StyledDropZone>
                  {
                    isDragActive ?
                    <p>Drop the files here ...</p> :
                    <div className="dropInstruct">
                      <Icon icon="fa-solid:long-arrow-alt-down" height="62" inline />
                      <div>
                        <p>Drag &apos;n&apos; drop some Video file here</p>
                        <p>or click to select video</p>
                        <em>Only *.mp4 with AAC Audio Codec videos will be accepted</em>
                      </div>
                    </div>
                  }
                  </StyledDropZone>
                </Col>
              </Row>
              }
              {
                (acceptedFiles.length > 0 || videoFile?.fname) && 
                  <>
                  <Row style={{marginTop: '1rem'}}>
                    <VideoDetails>
                      <span>File Name</span>
                      <Badge variants="secondary">{videoFile?.fname && videoFile.fname }</Badge>
                    </VideoDetails>
                  </Row>
                  <Row style={{marginTop: '1rem'}}>
                    <VideoDetails>
                      <span>Type</span>
                      <Badge variants="secondary">{videoFile?.ftype && videoFile.ftype}</Badge>
                    </VideoDetails>
                    <VideoDetails>
                      <span>Size</span>
                      <Badge variants="secondary">{videoFile?.fsize && prettyBytes(videoFile.fsize)}</Badge>
                    </VideoDetails>
                    <VideoDetails>
                      <span>Length (min)</span>
                      <Badge variants="secondary">{videoFile?.vl && parseFloat(videoFile.vl / 60).toFixed(2)}</Badge>
                    </VideoDetails>
                    <VideoDetails>
                      <span>Resolution</span>
                      <Badge variants="secondary">{videoFile?.vw && `${videoFile.vw} x ${videoFile.vh}` }</Badge>
                    </VideoDetails>
                  </Row>
                  <Row style={{marginTop: '1rem'}}>
                    <VideoDetails size={11}>
                      <ProgressButton variants={isUploaded===3 ? "success" : "primary"} onClick={()=>PreUploadFn()} progress={uplProgress[2]} disabled={isUploaded===3 ? true : false}>
                        <span className="line1">
                          <Icon icon="akar-icons:cloud-upload" inline /> 
                          {isUploaded===0 ? "Start upload": isUploaded===1 ? "Uploading...": isUploaded===2 ? "Paused" : isUploaded===3 && "Uploaded"}
                        </span>
                        <span className="line2">{uplProgress[2]}% completed</span>
                      </ProgressButton>
                    </VideoDetails>
                  </Row>
                  </>
              }
              
          </Col>
        </Row>
    </Container>
  )
}

export default VideoUplSection