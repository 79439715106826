import { SideNavDiv, NavBrand, SideNavContent, ToggleBtnDiv, NavBrandCont, SideNavMenu, SideNavMenuTitle } from "./sidenav.style"
import {Badge, Button} from "../UI"
// import {NavLink} from 'react-router-dom'
import { Icon } from "@iconify/react"
import {useRecoilState} from 'recoil'
import { NavSizeState } from "../../states/atoms"

const SideNav = () => {
    const [navSize, setNavSize] = useRecoilState(NavSizeState)

    const toggleNav = () => {
        navSize === 2 ? setNavSize(1) : setNavSize(2)
        console.log(navSize)
    }

    return(
        <SideNavDiv>
            <NavBrandCont>
                <NavBrand>
                    <div>
                        <span>PWB&apos;s</span>
                        <span>GenFac</span>
                    </div>
                    <Badge variants="success" edge css={{visibility: navSize === 1 ? 'hidden' : 'visible'}}>Stable</Badge>
                </NavBrand>
                {/* <ToggleBtnDiv>
                    <Button variants="secondary" onClick={toggleNav} css={{marginLeft: navSize === 2 ? '-1rem' : 'auto', zIndex: 9999}}>
                        <Icon icon="heroicons-outline:menu-alt-2" />
                    </Button>
                </ToggleBtnDiv> */}
                
            </NavBrandCont>
            <SideNavContent>
                <SideNavMenu to="" end size={navSize}>
                    {/* <SideNavMenu > */}
                        <Icon icon="radix-icons:pie-chart" width={navSize === 2 ? "20" : '28'} inline />
                        <span>Dashboard</span>
                    {/* </SideNavMenu> */}
                </SideNavMenu>
                <SideNavMenuTitle>Lectures</SideNavMenuTitle>
                <SideNavMenu to="lecture/add" size={navSize}>
                    
                        <Icon icon="fluent:video-add-20-filled" width={navSize === 2 ? "20" : '24'} inline />
                        <span>Add Lecture</span>
                    
                </SideNavMenu>
                <SideNavMenu to="lecture/saved" size={navSize}>
                    <Icon icon="mdi-light:memory" width={navSize === 2 ? "20" : '24'} inline />
                    <span>Saved Lectures</span>
                </SideNavMenu>
                <SideNavMenu to="lecture/draft" size={navSize}>
                    
                        <Icon icon="fluent:cloud-archive-32-filled" width={navSize === 2 ? "20" : '24'} inline />
                        <span>Drafted Lectures</span>
                    
                </SideNavMenu>
                <SideNavMenu to="lecture/manage" size={navSize}>
                    
                        <Icon icon="fluent:video-clip-multiple-20-filled" width={navSize === 2 ? "20" : '24'} inline />
                        <span>Manage Lectures</span>
                    
                </SideNavMenu>
                <SideNavMenuTitle>Students</SideNavMenuTitle>
                <SideNavMenu to="student/add" end size={navSize}>
                    
                        <Icon icon="icon-park-twotone:add-user" width={navSize === 2 ? "20" : '24'} inline />
                        <span>Add Student</span>
                    
                </SideNavMenu>
                <SideNavMenu to="student/blkadd" end size={navSize}>
                    
                        <Icon icon="uiw:file-excel" width={navSize === 2 ? "20" : '24'} inline />
                        <span>Bulk Student Upload</span>
                    
                </SideNavMenu>
                <SideNavMenu to="student/modify" end size={navSize}>
                    
                        <Icon icon="la:user-edit" width={navSize === 2 ? "20" : '24'} inline />
                        <span>Modify Student Data</span>
                    
                </SideNavMenu>
            </SideNavContent>
            
        </SideNavDiv>
    )
}

export default SideNav