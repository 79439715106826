import { styled } from "../../../stitches.config";

const BadgeStyled = styled('span', {
    fontSize: '$1',
    fontWeight: '500',
    padding: '2px 5px',
    margin: '0px 6px',
    backgroundColor: '$amber9',
    
    textAlign: 'center',
    letterSpacing: 'normal',
    width: 'max-content',
    userSelect: 'none',
    variants: {
        color: {
            primary: {
                background: '$blue2',
                color: '$blue9'
            },
            secondary:{
                background: '$gray2',
                color: '$gray9'
            },
            success: {
                background: '$success',
                color: '$green12'
            },
            successA: {
                background: '$green1',
                color: '$green10'
            },
            danger: {
                background: '$red9',
                color: '$red1'
            },
            warning: {
                background: '$yellow9',
                color: '$yellow12'
            },
            themedTrans: {
                background: '$btnTransBg',
                color: '$btnTransColor'
            }
        },
        edge: {
            true: {
                borderRadius: '10px',
            },
            false: {
                borderRadius: '2px'
            }
        }
    },
    compoundVariants: [
        {
            color: 'themedTrans',
            edge: true,
            css: {
                borderRadius: '10px',
            }
        },
        {
            color: 'secondary',
            edge: false,
            css: {
                borderRadius: '2px'
            }
        }
    ]
})

export default function Badge({children, variants, edge, css, ...props}){
    return(
        <BadgeStyled color={variants} edge={edge} css={css} {...props} >
            {children}
        </BadgeStyled>
    )
}