import { createStitches } from '@stitches/react'
import { gray, blue, red, green, yellow, lime, amber, purple, blackA, whiteA, greenA } from '@radix-ui/colors'

export const { config, createTheme, css, getCssText, globalCss, styled, theme, keyframes } = createStitches({
  prefix: "gen",
  theme: {
    colors: {
      ...amber,
      ...blue,
      ...gray,
      ...red,
      ...green,
      ...yellow,
      ...lime,
      ...purple,
      ...blackA,
      ...whiteA,
      
      background: '#f1f1f1',
      foreground: '$gray12',
      modalBg: '$gray2',
      themedBtBg: '$purple4',
      themedBtColor: '$purple11',
      secTitle: '$purple8',
      inputBg: '$purple4',
      btnTransBg: '$purple4',
      btnTransColor: '$purple11',
      themedBtn: {
        bg: '$gray2',
        color: '#ffffff'
      },
      navbarBg: '#ffffff',
      NavbarColor: '#111111',

      neutral: '$purple4',
      neutralColor: '$gray9',
      primary: '#0072F5',
      secondary: '#9750DD',
      success: '#17C964',
      warning: '#F5A524',
      error: '#F31260',

      gradient: 'linear-gradient(112deg, #06B7DB -63.59%, #FF4ECD -20.3%, #0072F5 70.46%)',

      hiContrast: 'hsl(206,10%,5%)',
      loContrast: 'white',

      ejsBg: '$purple1',
      ejsBorder: '$purple4',
      ejsColor: '$gray12',
      ejsPopover: '$whiteA12',
      ejsPopoverBdr: '$purple4',

      cardBg: '$purple2',
    },
    space: {
      1: '5px',
      2: '10px',
      3: '15px',
      4: '20px',
      5: '25px',
      6: '35px',
    },
    sizes: {
      1: '5px',
      2: '10px',
      3: '15px',
      4: '20px',
      5: '25px',
      6: '35px',
    },
    fontSizes: {
      1: '12px',
      2: '13px',
      3: '15px',
      4: '17px',
      5: '19px',
      6: '21px',
    },
    fonts: {
      system: '"Manrope", sans-serif',
      monospace: '"Space Mono", monospace'
    },
  },
  utils: {
    mX: (value) => ({
      marginLeft: value,
      marginRight: value,
    }),
    mY: (value) => ({
      marginTop: value,
      marginBottom: value,
    }),
    mt: (value) => ({
      marginTop: value,
    }),
    mb: (value) => ({
      marginBottom: value,
    }),
    pX: (value) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    pY: (value) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
    nvw: (value) => ({
      width: value
    })
  },
  media: {
    sm: '(min-width: 576px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 992px)',
    xl: '(min-width: 1400px)'
  },
})