import { styled } from "../../../stitches.config"
import Button from "./button"

const ProgressBtn = styled(Button, {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    width: '100%',
    transition: 'background 2s ease-in-out',

    '& .progressWrap':{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        fontSize: '1.2rem',

        '& .line1': {
            display: 'flex',
            flex: 'row',
            zIndex: '1000',
            fontSize: '1rem',
            
            '& > .iconify': {
                marginRight: '0.3rem'
            },
        },

        '& .line2': {
            display: 'flex',
            flex: 'row',
            fontSize: 12,
            zIndex: '1000',
        },

        

        '& .progressEl': {
            content: ' ',
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
            background: 'rgba(0, 0, 0, 0.8)',
            transition: 'width 2s ease-in-out'
        }
        
    },

    
})

const ProgressButton = ({children, variants, disabled, progress, ...rest}) => {
    console.log(`progress: ${progress}`)
    return(
        <ProgressBtn colors={variants} data-style="fill-horizontal" 
        data-perspective data-horizontal disabled={disabled} {...rest}>
            <div className="progressWrap" >
                <div className="progressEl" style={{width: `${progress}%`}}></div>
                {children}
            </div>
        </ProgressBtn>
    )
}

export default ProgressButton