import React from 'react'
import ReactDOM from 'react-dom/client'
import { RecoilRoot } from 'recoil'
import { BrowserRouter } from "react-router-dom";
import App from './App'
import "@fontsource/manrope"
import "@fontsource/space-mono"
import {globalStyles} from './styles/global.style'
import ThemeProvider from './styles/themeSwitch'
import { ApolloClient, InMemoryCache, ApolloProvider, gql } from '@apollo/client'

import {darkTheme, lightTheme} from './styles/theme'

globalStyles()

const client = new ApolloClient({
  uri: 'https://genfac-graphql.azurewebsites.net/GenFacAPI',
  // uri: 'http://localhost:7071/GenFacAPI',
  cache: new InMemoryCache(),
});

ReactDOM.createRoot(document.getElementById('root')).render(
    <BrowserRouter>
      <RecoilRoot>
        <ApolloProvider client={client}>
          <ThemeProvider />
          <App/>
        </ApolloProvider>
      </RecoilRoot>
    </BrowserRouter>
 
)
