import { styled } from "../../../stitches.config";

const RowStyled = styled("div", {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 'calc(1 * 0rem)',
    marginRight: 'calc(-0.75 * 1rem)',
    marginLeft: 'calc(-0.75 * 1rem)',
    gap: '10px 0px',

    ['& > *']: {
        flexShrink: 0,
        width: '100%',
        maxWidth: '100%',
        paddingRight: 'calc(0.2 * 1rem)',
        paddingLeft: 'calc(0.2 * 1rem)',
        marginTop: 0
    },

    variants: {
        col: {
            1: {
                '& > * ': {
                    flex: '0 0 auto',
                    width: '100%'
                }
            },
            2: {
                '& > * ': {
                    flex: '0 0 auto',
                    width: '50%'
                }
            },
            3: {
                '& > * ': {
                    flex: '0 0 auto',
                    width: '33.3333333333%'
                }
            },
            4: {
                '& > * ': {
                    flex: '0 0 auto',
                    width: '25%'
                }
            },
            5: {
                '& > * ': {
                    flex: '0 0 auto',
                    width: '20%'
                }
            },
            6: {
                '& > * ': {
                    flex: '0 0 auto',
                    width: '16.6666666666%'
                }
            },
            auto: {
                '& > * ': {
                    flex: '0 0 auto',
                    width: 'auto'
                }
            }
        },

        justify:{
            between: {
                justifyContent: 'space-between',
            },
            around: {
                justifyContent: 'space-around'
            },
            center: {
                justifyContent: 'center'
            },
            start: {
                justifyContent: 'start'
            }
        },

        align: {
            center: {
                alignItems: 'center',
            },
            top: {
                alignItems: 'start',
            },
            bottom: {
                alignItems: 'end'
            }
        }
    }
})

const Row = ({children, css, col, justify, align, ...props}) => {
    // console.log(props)
    return(
        <RowStyled css={{...css}} col={col} justify={justify} align={align} {...props}>
            {children}
        </RowStyled>
    )

}

export default Row