import { useRecoilValue, useSetRecoilState } from "recoil"
import { useLiveQuery } from "dexie-react-hooks"
import { store } from "../../../states/db"
import { UserIDSelector, viewQuizId, viewQuizState, UserInstiSelector } from "../../../states/atoms"
import { Col, Row, Button, Container, Select } from "../../../components/UI"
import { QuizBtn, DeleteBtn, AddQuizBtn } from "./quizSec.style"
import { Icon } from "@iconify/react"
import QuizModal from "./quizModal"
import getUnixTime from "date-fns/getUnixTime"
import { useForm } from "react-hook-form"
import { useState, useEffect } from "react"

const QuizSec = ({offCourse}) => {
    const useridstate = useRecoilValue(UserIDSelector)
    const setQuizId = useSetRecoilState(viewQuizId);
    const setShow = useSetRecoilState(viewQuizState);
    const UserInsti = useRecoilValue(UserInstiSelector)
    const [quizModeState, SetQuizModeState] = useState("")
    const {register, getValues} = useForm()

    console.log('quizCourseId:'+ offCourse)

    const deleteQuiz = async(e, uuid) => {
        e.stopPropagation()
        console.log(`Deleting: ${uuid}`)
        const deleteQuiz = await store.quizCurr.delete(uuid)
        const deleteEntry = await store.currentEdits.where('type').equals(`${useridstate}quizCurr`).modify(x => x.value.splice(e => e.indexOf(uuid), 1))
        console.log(deleteQuiz,deleteEntry)
    }

    const handleShow = async(id, isSaved) => {
        const uniqTimeID = getUnixTime(new Date()) 
        const Qlid = `${UserInsti.insti.id}${UserInsti.user.id}leQ${uniqTimeID}`
        const qid = id || Qlid
        const scid = offCourse || null
        setQuizId({qid, isSaved, scid})
        if(!scid){
            console.log(qid)
            const exist = await store.currentEdits.get({type: `${useridstate}quizCurr`})
            if(await exist?.value.find(e => e.qid === qid)){
                console.log('%cAlready Saved, so returning...',"font-weight: bold; color: black; background: white; border: 1px dashed #4d4d4d; padding: 2px 5px; border-radius: 10px;")
            }
            else if(await exist instanceof Object){
                console.log('Table already exist, so updating')
    
                // const merged = exist.push([quizId.qid])
                await store.currentEdits.where('type').equals(`${useridstate}quizCurr`).modify(x => x.value.push({qname: null, qid}) )
    
            }else{
                console.log('Table is empty, so adding new entry')
                await store.currentEdits.put({type: `${useridstate}quizCurr`, value: [{qname: null, qid: qid}]})
            }
        }else {
            const exist = await store.savedCourses.get(offCourse)
            console.log(exist)
        }
        setShow(true)
    };
    const list = useLiveQuery(async() => offCourse ? store.savedCourses.get(offCourse) : store.currentEdits.get({type: `${useridstate}quizCurr`}), [offCourse])

    const setQuizMode = async() => {
        const qmode = getValues('quizMeta.mode')
        SetQuizModeState(qmode)
        console.log(qmode)
        const exist = await store.currentEdits.get({type: `${useridstate}quizMeta`})
        if(exist) await store.currentEdits.update(`${useridstate}quizMeta`, {value: {mode:qmode}})
        else await store.currentEdits.put({type: `${useridstate}quizMeta`, value: {mode: qmode}})
    }

    useEffect(() => {
        const setQmode = async() => {
            const qmtemp = await store.currentEdits.get({type: `${useridstate}quizMeta`})
            console.log(`IndexDB: ${qmtemp?.value?.mode}`)
            if(qmtemp) SetQuizModeState(qmtemp?.value?.mode)
            else SetQuizModeState("show1")
        }

        setQmode()
        
    }, [])

    if(offCourse){
    return(<Container>
        <Select label="Quiz Mode: " value={quizModeState} name="quizMode" {...register("quizMeta.mode", {required: true, onChange: () => setQuizMode()})}>
            <option value="show1">Show 1 Quiz Panel</option>
            <option value="showall">Show All the Quiz Panel</option>
        </Select>
        <Row col={4}>
            {
                list?.quiz?.map((li) =>
                <QuizBtn key={li.qid} data-uuid={li.qid} onClick={()=>handleShow(li.qid,true)}>
                    Quiz: <small>{li.qname || li.qid}</small>
                    <DeleteBtn variants="secondary" onClick={(e) => deleteQuiz(e,li.qid)}>
                        <Icon icon="ic:round-clear" inline />
                    </DeleteBtn>
                </QuizBtn>)
            }
            <Col>
                <AddQuizBtn variants="secondary" onClick={() => handleShow(null,true)}>
                    <Icon icon="bi:list-check" height="24" style={{marginRight: '0.5rem'}} /> Add Quiz
                </AddQuizBtn>
            </Col>
        </Row>
        <QuizModal />
    </Container>)}

    return(<Container>
        <Select label="Quiz Mode: " value={quizModeState} name="quizMode" {...register("quizMeta.mode", {required: true, onChange: () => setQuizMode()})}>
            <option value="show1">Show 1 Quiz Panel</option>
            <option value="showall">Show All the Quiz Panel</option>
        </Select>
        <Row col={4}>
            {
                list?.value.map((li) =>
                <QuizBtn key={li.qid} variants="secondary" data-uuid={li.qid} onClick={()=>handleShow(li.qid,false)}>
                    Quiz <small>{li.qname || li.qid}</small>
                    <DeleteBtn variants="secondary" onClick={(e) => deleteQuiz(e,li.qid)}>
                        <Icon icon="ic:round-clear" inline />
                    </DeleteBtn>
                </QuizBtn>
            )}
            <Col>
                <AddQuizBtn variants="secondary" onClick={() => handleShow(null,false)}>
                    <Icon icon="bi:list-check" height="24" style={{marginRight: '0.5rem'}} /> 
                    <span>Add Quiz</span>
                </AddQuizBtn>
            </Col>
        </Row>
        
        <QuizModal />

    </Container>)
}

export default QuizSec