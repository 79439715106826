import { styled } from "../../../stitches.config"
import {forwardRef} from 'react'

const CardDiv = styled("div", {
    position: 'relative',
    display: 'flex',
    minWidth: '0',
    height: 'auto',
    wordWrap: 'break-word',
    backgroundColor: "$cardBg",
    color: '$cardColor',
    backgroundClip: 'border-box',
    borderRadius: 5
})

const CardBodyDiv = styled("div", {
    flex: '1 1 auto',
    padding: '1rem',
})

const CardImgDiv = styled("img", {
    width: '100%',
    height: 'auto',

    variants: {
        position: {
            top: {
                borderTopRightRadius: 5,
                borderTopLeftRadius: 5
            },
            bottom: {
                borderBottomRightRadius: 5,
                borderBottomLeftRadius: 5
            }
        }
    }
})

const Card = ({children, css, ...props}) => {
    return(
        <CardDiv css={css} {...props}>
            {children}
        </CardDiv>
    )
}

const CardBody = ({children, ...props}) => {
    return(
        <CardBodyDiv {...props}>
            {children}
        </CardBodyDiv>
    )
}

const CardImg = ({src, css, position, ...props}) => {
    return(
        <CardImgDiv src={src} position={position} css={css} {...props} /> 
    )
}

export default Object.assign(Card, {
    Body: CardBody,
    Img: CardImg
})
