import createRandomHex from "./randomHex"
import {ThumbCont, PrevCardMeta} from './lecCard.style'
import { Card } from "../UI"
import { useId } from "react"

const CardVert = ({ltitle, stitle, subject, dur, cover, files}) => {
    console.log(files)
    const gradId = useId();
    const thumbs = cover ? 
        <Card.Img src={`https://playwithbot.com/cdn-cgi/imagedelivery/Tj8oorGJn-kb8MPZdUcX7A/${cover.imgId}/thumb400`} alt="coverImgPreview" position="top" css={{objectFit: 'cover', borderRadius: 15, height: 200}} />
        : files.length > 0 && files.map(file => <Card.Img key={file.name} src={file.preview} position="top" css={{objectFit: 'cover', borderRadius: 15, height: 200}} alt="cover-img" />)
    return(
        <>
            <ThumbCont>
                {cover || files.length > 0 ? thumbs : 
                    <svg xmlns="http://www.w3.org/2000/svg" >
                        <linearGradient id={gradId}>
                            <stop offset="5%" stopColor={`#${createRandomHex()}`} />
                            <stop offset="95%" stopColor={`#${createRandomHex()}`} />
                        </linearGradient>
                        <rect x="0" y="0" rx="15" width="260px" height="100%" fill={`url(#${gradId})`} />
                        
                    </svg>
                }
                <p className="sub">{subject}</p>
                <p className="dur">{dur}</p>
            </ThumbCont>
            <Card.Body>
                <PrevCardMeta>
                    <p>{ltitle}</p>
                    <small>{stitle}</small>
                </PrevCardMeta>
                
            </Card.Body>
        </>
    )
}

export default CardVert