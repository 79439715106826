import { createTheme } from "../../stitches.config"
import { purpleDark, grayDark, blueDark, redDark, yellowDark, greenDark} from '@radix-ui/colors'

export const darkTheme = createTheme('dark-theme',{
    colors: {
      background: '#000000',
      foreground: '$whiteA12',
      modalBg: '$gray2',
      themedBtBg: '$gray1',
      themedBtColor: '$white4',
      secTitle: '$whiteA12',
      inputBg: '$gray1',
      btnTransBg: '$gray2',
      btnTransColor: '$gray11',

      themedBtn: {
        bg: '$gray2',
        color: '#ffffff'
      },
      navbarBg: '#121212',
      navbarColor: '#ffffff',

      primary: '$blue9',
      success: '$green9',
      warning: '$yellow9',
      error: '$red9',
      secondary: '$gray9',
      neutral: '$gray4',
      neutralColor: '$gray12',
      

      ejsBg: '#000000',
      ejsBorder: '$gray4',
      ejsColor: '$whiteA12',
      ejsPopover: '$gray2',
      ejsPopoverColor: '$whiteA12',
      ejsPopoverBdr: '$gray8',

      cardBg: '$gray2',

      ...grayDark,
      ...purpleDark,
      ...blueDark,
      ...redDark,
      ...yellowDark,
      ...greenDark
    }
})

export const lightTheme = createTheme('light-theme', {
  colors:{
    
  }
})