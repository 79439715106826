import { Helmet } from "react-helmet"
import { Icon } from '@iconify/react';
import { Container, Row, Col } from "../../components/UI";
import { styled, keyframes } from "../../../stitches.config";

const change = keyframes({
    '0%': {color: '$amber9'},
    '100%': {color: '$blackA9'}
})

const NotReadyNotice = styled("div", {
    fontSize: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    [`& .iconify`]: {
        animation: `${change} 1s forwards infinite`,
        animationDelay: '2s',
    },

    '& div':{
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '1rem'
    }
})


const NotReadyPg = () => {
    return(
        <Container>
            <Row justify="center" align="center">
                <Col size="4">
                    <Helmet>
                        <title>This Option is not ready!</title>
                    </Helmet>
                    <NotReadyNotice>
                        <Icon icon="et:caution" width="32" />
                        <div>
                            <p>This Option is not ready yet!</p>
                            <span>Soon it will be released!</span>
                        </div>
                    </NotReadyNotice>
                    
                </Col>
            </Row>
            
        </Container>
    )
}

export default NotReadyPg