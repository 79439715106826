import { styled } from "../../../stitches.config"

const TableContainer = styled("table", {
    marginTop: '0rem',
    marginBottom: '1rem',
    border: 0,
    width: '100%'
})

const ThemedTHead = styled("thead", {
    background: '$gray1',
    color: '$gray9',
    fontWeight: 600,
    
    '& th':{
        padding: '0.4rem 0rem',
    }
})

const TrowStyled = styled("tr", {
    paddingTop: '2rem',
    paddingBottom: '1rem',
    height: '3rem',
    background: 'green1',

    '&:hover': {
        backgroundColor: '$gray4',
        color: '$white9',
        cursor: 'pointer'
    },

    '& td': {
        paddingInline: '1rem'
    },

    '& td.coverTd': {
        width: 140,
        height: 60,
    },
    
    '& td.meta': {
        '& small': {
            fontSize: 12
        },
        '& p': {
            fontSize: '1rem',
            fontWeight: 600,
        }
    },

    '& td.quiz': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        fontSize: 14
    }
})

const TableUI = ({children}) => {
    return(
        <TableContainer>
            {children}
        </TableContainer>
    )
}

const TableHead = ({children}) => {
    return(
        <ThemedTHead>
            <tr>
             {children}
            </tr>
            
        </ThemedTHead>
    )
}

const Trow = ({children, ...props}) => {
    return(
        <TrowStyled {...props}>
            {children}
        </TrowStyled>
    )
}

const Table = {
    container: TableUI,
    THead: TableHead,
    Tr: Trow
}

export default Table