import { styled } from "../../../stitches.config";

const ContStyled = styled('div', {
    width: '100%',
    paddingRight: 'calc(1.5rem * 0.5)',
    paddingLeft: 'calc(1.5rem * 0.5)',
    marginRight: 'auto',
    marginLeft: 'auto',

    variants:{
        width:{
            sm:{
                maxWidth: '540px'
            },
            md:{
                maxWidth: '720px'
            },
            lg: {
                maxWidth: '992px'
            },
            xl: {
                maxWidth: '1200px'
            },
            xxl: {
                maxWidth: '1400px'
            }
        }
    }
})

const Container = ({children, width}) => {
    const sm = {'@sm': 'sm'}
    const md = {'@md': 'md'}
    const lg = {'@lg': 'lg'}
    const xl = {'@xl': 'xl'}

    let fluidSize = width === 'sm' ? {...sm} : width === 'md' ? {...sm, ...md} : width === 'lg' ? {...sm, ...md, ...lg} : width === 'xl' && {...sm, ...md , ...lg, ...xl}
    
    return(
        <ContStyled width={{...fluidSize}}>
            {children}
        </ContStyled>
    )

}

export default Container