import { useEffect } from "react"
import { darkTheme, lightTheme } from "./theme"
const ThemeProvider = () => {
    
    useEffect(() => {
        const currTheme = localStorage.getItem('theme')
        const theme = currTheme != null ? currTheme : 'dark-theme'

        if(theme === 'dark-theme'){
            localStorage.setItem('theme', theme)
            document.body.classList.add(darkTheme)
            document.body.style.colorScheme = "dark"
        }else{
            localStorage.setItem('theme', 'light-theme')
            document.body.classList.add(lightTheme)
            document.body.style.colorScheme = "light"
        }

    },[])
}

export default ThemeProvider