import { styled } from "../../../../stitches.config";
import { Col } from "../../../components/UI";

export const StyledDropZone = styled("div", {
    border: '1px dashed $green9',
    borderRadius: 5,
    paddingTop: '1rem',
    paddingBottom: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',

    '& .iconify': {
        marginRight: '1rem'
    },

    '& p': {
        fontWeight: 500,
        fontSize: '1rem',
        opacity: '0.5',
        lineHeight: '1.2rem',
        letterSpacing: '1px'
    },

    '& em': {
        fontSize: 12
    },

    '& .dropInstruct': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    }
})

export const VideoContainer = styled("video", {
    background: '$gray4',
    alignSelf: 'stretch',
    height: 'auto',
    width: '90%',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
})

export const VideoDetails = styled(Col,{
    display: 'flex',
    flexDirection: 'column',

    '& span:first-child':{
        fontSize: 12
    },

    '& span:nth-child(2)':{
        fontSize: 12,
        fontFamily: 'monospace'
    }
})
