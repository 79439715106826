import { styled } from "../../../../stitches.config";

export const NonInp = styled("span", {
    fontSize: '1rem',
    fontFamily: '$monospace',
    display: 'flex',
    flexDirection: 'column',
    '& .title': {
        
        fontWeight: '300',
    },
    '& .det': {
        fontSize: '0.8rem',
        display: 'flex',
        flexDirection: 'row'
    }
})