import { useState, useEffect, useLayoutEffect } from 'react';
import { LoadingContainer, PageContainer, SectionTitle, PageTitle } from './addcoursepg.style'
import {Helmet} from "react-helmet";
import MetaForm from './metaForm'
import { css } from '../../../stitches.config'
import TheoryEditor from './theoryEditor'
import VideoUplSection from './uploadVideo';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import { store } from '../../states/db';
import { dynamicNavs, UserIDSelector } from '../../states/atoms';
import QuizSec from './quizSec';
import { Icon } from '@iconify/react';
import CoverImage from './coverImage';


// const TheoryEditor = dynamic(() => import("./theoryEditor"),{ssr: false})

const Addcoursepg = () => {
    const useridstate = useRecoilValue(UserIDSelector)
    const setDynamicNav = useSetRecoilState(dynamicNavs)
    const [offlineData, SetOfflineData] = useState(null)
    const matches = useParams()
    useLayoutEffect(() => {
      setDynamicNav(matches.id ? 3 : 2)
    })
    
    console.log(matches)
    useEffect(()=> {
            // console.log(location.state)

            const getDatafromLoc = async() => {
                const res = matches?.id ? await store.savedCourses.get(parseInt(matches.id))
                : await store.currentEdits.bulkGet([`${useridstate}metaFormCurr`, `${useridstate}videoCurr`, `${useridstate}editorCurr`, `${useridstate}quizCurr`, `${useridstate}coverCurr`])
                console.log(res)
                SetOfflineData(res)
            }
            getDatafromLoc()
        
    }, [])

    return offlineData ?
        <PageContainer>
        <Helmet>
          <title>{matches?.id ? `${matches?.id} : Add Course - GenFac` : 'Add Course - GenFac'}</title>
        </Helmet>
          {matches?.id && 
          <div>
            <PageTitle>#{matches.id}</PageTitle>  
          </div>}
          <div style={{marginTop: '1rem'}}>
            <SectionTitle>Meta Information</SectionTitle>
            <MetaForm savedCourse={offlineData[0]?.value || offlineData.meta ||false} cid={matches.id} />
          </div>
          <div style={{marginTop: '2rem'}}>
            <SectionTitle>Upload Lecture Video Content</SectionTitle>
            <VideoUplSection savedCourse={offlineData[1]?.value || offlineData.video || false} />
          </div>
          <div style={{marginTop: '2rem'}}>
            <SectionTitle>Theory Content</SectionTitle>
            <TheoryEditor savedCourse={offlineData[2]?.value || offlineData.editor || false} cid={matches.id} />
          </div>
          <div style={{marginTop: '2rem'}}>
            <SectionTitle>Add Quizes</SectionTitle>
            <QuizSec offCourse={ parseInt(matches.id)|| false} />
          </div>
          <div style={{marginTop: '6rem'}}>
            <SectionTitle>Add Lecture Cover</SectionTitle>
            <CoverImage savedCourse={offlineData[4]?.value || offlineData.cover || false} />
          </div>
        </PageContainer>
    : <LoadingContainer><Icon icon="line-md:loading-twotone-loop" inline /> Loading...</LoadingContainer>
}

export default Addcoursepg